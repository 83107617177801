import classNames from 'classnames';
import * as React from 'react';
import { mapToCssModules } from '../../shared-utils/utilities';
import { IButtonProps } from './Button.props';

/**
 * Button component
 */
export default class Button extends React.Component<IButtonProps> {

    public static defaultProps: Partial<IButtonProps> = {
        tag: 'button',
        outline: false
    };
    public props: IButtonProps;

    constructor(props: IButtonProps) {
        super(props);
        this.props = props;
        this.onClick = this.onClick.bind(this); // put this in componentDidMount
    }

    public render(): JSX.Element {
        const {
            active,
            block,
            className,
            cssModule,
            color,
            outline,
            size,
            tag,
            innerRef,
            ...attributes
        } = this.props;
        const classes = mapToCssModules(
            classNames(
                className,
                'btn',
                outline && color ? `btn-outline-${color}` : color && outline === false ? `btn-${color}` : color === undefined && outline === true ? `btn-outline` : '',
                size ? `btn-${size}` : false,
                block ? 'btn-block' : false,
                { active, disabled: this.props.disabled }
            ),
            cssModule
        );

        let Tag = tag || 'button';

        if (attributes.href && Tag === 'button') {
            Tag = 'a';
        }

        return (
            <Tag
                type={(Tag === 'button' && attributes.onClick) ? 'button' : undefined}
                {...attributes}
                className={classes}
                ref={innerRef}
                onClick={this.onClick}
            />
        );
    }

    private onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.disabled) {
            e.preventDefault();

            return;
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }
}
