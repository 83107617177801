import * as React from 'react';
import { KeyCodes } from '@msdyn365-commerce-modules/utilities';

export interface ISidebarInfoProps {
    text: string;
}

// tslint:disable-next-line:no-empty-interface
interface ISidebarInfoState {
    showSidebar: boolean;
}
/**
 * Configuration entity interface.
 */
export interface IConfiguration {
    type?: string;
    description?: string;
}
// tslint:disable-next-line:class-name
export interface IDXC_GetConfigurationResponse {
    configuration?: IConfiguration[];
    success?: boolean;
    errorText?: string;
}

/**
 *
 * The PromoCode component renders the promocode section.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class SidebarInfo extends React.Component<ISidebarInfoProps, ISidebarInfoState> {
    private sidebarNode: React.RefObject<HTMLDivElement>;

    constructor(props: ISidebarInfoProps, state: ISidebarInfoState) {
        super(props);

        this.sidebarNode = React.createRef();
        this.state = {
            showSidebar: false
        };
        this._toggleSidebar = this._toggleSidebar.bind(this);
    }

    public componentDidMount(): void {
        window && window.addEventListener('keyup', this._keyup);
        document.body && document.addEventListener('mousedown', this._handleClickOutside);
    }

    public componentWillUnmount(): void {
        window && window.removeEventListener('keyup', this._keyup, false);
        document.body && document.removeEventListener('mousedown', this._handleClickOutside, false);
    }

    public render(): JSX.Element {
        
        const wrapperClassName = 'citta-sidebar';
        const { showSidebar } = this.state;
        const { text } = this.props
        if (!showSidebar) {
            return <div className={wrapperClassName}>
                <span>Buy now pay later  </span>
                <span className={`${wrapperClassName}_link`} onClick={this._toggleSidebar}>
                    {text}
                </span>
            </div>
        }
        return (
            <div>
                <div className="overlay"></div>
            <div className={wrapperClassName} ref={this.sidebarNode} >
                 <span>
                    Buy now, pay later
                </span>
                <span onClick={this._toggleSidebar}>
                    {text}
                </span>
                <div className={`${wrapperClassName}_content`} >

                    <div className="close-button-container">
                        <span className="close-button" onClick={this._toggleSidebar}>x</span>
                    </div>
                    { /* @ts-ignore */ }
                    {this.props.children}
                </div>
            </div>
            </div>
        );
    }
    private _toggleSidebar(): void {
        window.document.body.classList.toggle('menu-open', !this.state.showSidebar);
        window.document.body.classList.toggle('sidebar', !this.state.showSidebar);
        this.setState({
            showSidebar: !this.state.showSidebar
        });
    }

    private _keyup = (e: KeyboardEvent): void => {
        const { showSidebar } = this.state; 
        if (showSidebar && e.keyCode === KeyCodes.Escape) {
            this._toggleSidebar();
        } 
    };

    // tslint:disable-next-line:no-any
    private _handleClickOutside = (event: any) => {
        if (this.sidebarNode.current && !this.sidebarNode.current.contains(event.target)) {
            this._toggleSidebar();
        }
    };

}

export default SidebarInfo;