import * as React from 'react';

import { getTelemetryObject, INodeProps, ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { IActionContext } from '@msdyn365-commerce/core';
import { ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';


// import { IBuyboxData, IBuyboxResources } from '@msdyn365-commerce-modules/buybox';

import { ICittaBuyboxData, ICittaBuyboxResources } from '../../../../../src/modules/citta-buybox'

// @ts-ignore
import { Dropdown, IDropdownItemProps, IDropdownOnSelectionChangeNotification } from '../../../../components/citta-buybox/dropdown/dropdown';

export interface IBuyboxProductConfigureProps {
    product: SimpleProduct;
    productAttributes: SimpleProduct;
    channelConfiguration: SimpleProduct;

    productDimensions: ProductDimensionFull[];
    // resources: IBuyboxResources;
    resources: ICittaBuyboxResources;
    channelId: number;
    actionContext: IActionContext;
    errors: { [configureId: string]: string | undefined };

    dimensionChanged?(newValue: number): void;
}

export interface IBuyboxProductConfigureDropdownViewProps {
    ContainerProps: INodeProps;
    LabelContainerProps: INodeProps;

    heading: React.ReactNode;
    errors?: React.ReactNode;

    select: React.ReactNode;
}

export interface IBuyboxProductConfigureViewProps {
    ContainerProps: INodeProps;
    dropdowns: IBuyboxProductConfigureDropdownViewProps[];
}

let telemetryContent: ITelemetryContent;

export function getBuyboxProductConfigure(props: any, state: any, callbacks: any): IBuyboxProductConfigureViewProps | undefined {
    const {
        data: {
            product: { result: product },
            productDimensions: { result: productDimensions },
        },
        resources,
    } = props;

    const
        {
            errorState: {
                configureErrors,
            }
        } = state;

    // console.log('productDimensions', productDimensions)

    const
        {
            getDropdownName
        } = callbacks;
    // console.log('callbacks', callbacks)

    if (!product || !productDimensions) {
        return undefined;
    }

    const onChanged = async (notification: IDropdownOnSelectionChangeNotification) =>
        _onChanged(notification, callbacks.dimensionSelectedAsync);

    telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.friendlyName, props.telemetry);
    const dropdowns = productDimensions.map((productDimensionFull: ProductDimensionFull, id: number) => _mapProductDimensionFullToDropdownViewProps(productDimensionFull, resources, configureErrors, getDropdownName, onChanged, props.data, id));
    // console.log("dropsowns", dropdowns);
    if (!dropdowns || dropdowns.length === 0) {
        return undefined;
    }

    return {
        ContainerProps: {
            className: 'ms-buybox__configure'
        },
        dropdowns: dropdowns,

    };
}

const _onChanged = async (
    notification: IDropdownOnSelectionChangeNotification,
    dimensionChanged: (newValue: number, selectedDimensionValue: string) => Promise<void>,
): Promise<void> => {
    // console.log('notification.dropdownId', notification.dropdownId,'notification.selectId', notification.selectId);
    await dimensionChanged(+notification.dropdownId, notification.selectId);
};



const _mapProductDimensionFullToDropdownViewProps =
    (
        productDimensionFull: ProductDimensionFull,
        // resources: IBuyboxResources,
        resources: ICittaBuyboxResources,
        configureErrors: { [configureId: string]: string | undefined },
        getDropdownName: (dimensionType: number, resources: ICittaBuyboxResources) => string,
        dropdownCallback: (notification: IDropdownOnSelectionChangeNotification) => Promise<void>,
        // data: IBuyboxData,
        data: ICittaBuyboxData,
        id: number
    ): IBuyboxProductConfigureDropdownViewProps => {
        const dropdownName = getDropdownName(productDimensionFull.DimensionTypeValue, resources);
        // console.log('dropdownName', dropdownName)
        // console.log('dropdownCallback', dropdownCallback)
        const dropdownId = productDimensionFull.DimensionTypeValue.toString();

        let selectedDimensions: ProductDimension[] | undefined;
        selectedDimensions = data && data.product && data.product.result && data.product.result.Dimensions && data.product.result.Dimensions.length ? data.product.result.Dimensions : [];
        const dropdownToggleName = selectedDimensions && selectedDimensions[id] && selectedDimensions[id].DimensionValue &&
            selectedDimensions[id].DimensionValue!.Value || resources.selectDimensionFormatString.replace('{0}', dropdownName.toLocaleLowerCase());

        const result = data && data.productAttributes && data.productAttributes.result || [];
        let Onlinesizeimperial;
        let dropdownListStr: string | undefined = "";

        // result!.forEach(res => {
        //     if(res.Name === "Online size imperial" && res.TextValue){
        //         Onlinesizeimperial = res.TextValue;
        //     }
        // });

        for (var i = 0; i < result!.length; i++) {
            if (result![i].Name === "Size" && result![i].TextValue) {
                Onlinesizeimperial = result![i].TextValue || "";
            }
        }


        // result?.forEach(res => {
        //     if(res.Name === "AssociatedVariantSizesImperial" && res.TextValue){
        //         dropdownListStr = res.TextValue
        //     }
        // })


        for (var i = 0; i < result!.length; i++) {
            if (result![i].Name === "AssociatedVariantSizesImperial" && result![i].TextValue) {
                dropdownListStr = result![i].TextValue;
            }
        }


        const dropDownListArr = dropdownListStr?.split('|');
        const country = data && data.channelConfiguration && data.channelConfiguration.result && data.channelConfiguration.result.Currency;



        const dropdownList: IDropdownItemProps[] = productDimensionFull.DimensionValues
            ? productDimensionFull.DimensionValues.map<IDropdownItemProps>(dimensionValue => {
                return {
                    value: dimensionValue.Value || '',
                    id: dimensionValue.RecordId.toString()
                };
            })
            : [];


        let dropdownListImperial = JSON.parse(JSON.stringify(dropdownList));

        for (var i = 0; i < dropdownList.length; i++) {
            dropdownListImperial[i].value = dropDownListArr && dropDownListArr[i]
        }

        //    console.log('dropdownListImperial', dropdownListImperial)
        const errorMessage = configureErrors[dropdownId];

        return {
            ContainerProps: {
                className: 'ms-buybox__dropdown'
            },
            LabelContainerProps: {
                tag: 'label',
                className: 'ms-buybox__dropdown-quantity-label',
                htmlFor: `ms-buybox__dropown-quantity-input-${dropdownId}`
            },
            errors: errorMessage && (
                <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                    <span className='msi-exclamation-triangle' aria-hidden='true' />
                    <span>{errorMessage}</span>
                </span>
            ),
            heading: (
                <div>{dropdownName}</div>
            ),
            select: (
                dropdownList.length <= 1 ? (
                    <div className={'drop-down-item'}>
                        <div className='drop-down-item-container'>
                            <span className='drop-down-item-static'>{id === 1 && country === 'USD' && Onlinesizeimperial ? Onlinesizeimperial : dropdownToggleName}</span>
                            {/* <span className='drop-down-item-static'>{dropdownToggleName}</span> */}
                        </div>
                    </div>) :
                    (
                        <Dropdown
                            dropdownId={dropdownId}
                            dropdownName={dropdownName}
                            dropdownToggleName={id === 1 && country === 'USD' && Onlinesizeimperial ? Onlinesizeimperial : dropdownToggleName}
                            // dropdownList={dropdownList}
                            dropdownList={id === 1 && country === 'USD' ? dropdownListImperial : dropdownList}
                            onChange={dropdownCallback}
                            telemetryContent={telemetryContent}
                        />
                    )
            )
        };
    };