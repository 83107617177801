import classNames from 'classnames';
import * as React from 'react';
import { Target } from 'react-popper';
import { mapToCssModules } from '../../shared-utils/utilities';
import Button from '../citta-button/Button';
import { IDropdownContextType } from './DropdownProvider';
import { DropdownContext } from './UncontrolledDropdown';

export interface IDropdownToggleProps {
    caret?: boolean;
    color?: string;
    id?: string;
    children?: Node | string;
    className?: string;
    cssModule?: object;
    disabled?: boolean;
    onClick?: Function;
    'aria-haspopup'?: boolean;
    split?: boolean;
    tag?: string;
    nav?: boolean;
    'aria-label'?: string;
    href?: string;
    // tslint:disable-next-line:no-any prefer-method-signature
    toggle?: (e:any) => void;
    size?: string;
    useContext?: boolean;
    inNavbar?: boolean;
    isOpen?: boolean;
    'aria-expanded'?: boolean;
    'aria-labelledby'?: string;
    role?: string;
}

/**
 * Dropdown Toggle component
 */
export default class DropdownToggle extends React.Component<IDropdownToggleProps> {
    public static defaultProps: Partial<IDropdownToggleProps> = {
        'aria-haspopup': true,
        color: 'secondary',
        isOpen: false
    };

    public context: IDropdownContextType | null;

    constructor(props: IDropdownToggleProps) {
        super(props);
        this._onClick = this._onClick.bind(this);
        this.context = null;
    }

    public render(): JSX.Element {
        // tslint:disable-next-line:prefer-const -- cannot make const as ...props gets reassigned later
        let { className, color, cssModule, caret, split, nav, tag, toggle, inNavbar, isOpen, useContext, ...props } = this.props;
        let destructProps = { ...props };
        const ariaLabel = props['aria-label'] || 'Toggle Dropdown';
        const classes = mapToCssModules(
            classNames(className, {
                'dropdown-toggle': caret || split,
                'dropdown-toggle-split': split,
                'nav-link': nav
            }),
            cssModule
        );
        const children = props.children || <span className='sr-only'>{ariaLabel}</span>;

        let Tag: string | typeof Button;

        if (nav && !tag) {
            Tag = 'a';
            destructProps.href = '#';
        } else if (!tag) {
            Tag = Button;
            destructProps = {
                ...destructProps,
                // @ts-ignore -- color is missing from prop definition, where does this get applied to?
                color,
                cssModule
            };
        } else {
            Tag = tag;
            destructProps = {
                ...destructProps,
                // @ts-ignore -- tabIndex is missing from prop definition, need to extend htmlelement
                tabIndex: '0'
            };
        }

        if (useContext) {
            return (
                <DropdownContext.Consumer>
                    {(context: IDropdownContextType) => {
                        this.context = context;
                        return React.createElement(
                            // tslint:disable-next-line:no-any
                            context && context.inNavbar ? Tag : Target as any,
                            {
                                ...destructProps,
                                className: classes,
                                onClick: this._onClick,
                                'aria-expanded': this.context.isOpen,
                                component: !(context && context.inNavbar) && Tag
                            },
                            // @ts-ignore
                            children
                        );
                    }}
                </DropdownContext.Consumer>
            );
        }
        return React.createElement(
            // tslint:disable-next-line:no-any
            inNavbar ? Tag : Target as any,
            {
                ...destructProps,
                className: classes,
                component: !inNavbar && Tag,
                onClick: this._onClick,
                'aria-expanded': this.props.isOpen
            },
            // @ts-ignore
            children
        );
    }

    private _onClick(e: MouseEvent): void {
        if (this.props.disabled) {
            e.preventDefault();

            return;
        }

        if (this.props.nav && !this.props.tag) {
            e.preventDefault();
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }

        if (this.props.toggle) {
            this.props.toggle(e);
        } else if (this.context && this.context.toggle) {
            this.context.toggle();
        }
    }
}
