import { IBuyboxData, IBuyboxViewProps } from '@msdyn365-commerce-modules/buybox';
// import * as MsDyn365 from '@msdyn365-commerce/core';
import { IProductInventoryInformation } from '@msdyn365-commerce-modules/retail-actions';
import { Button, getPayloadObject, getTelemetryAttributes, IPopupProps, ITelemetryContent, Modal, ModalBody, ModalHeader, Popup } from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { PriceComponent } from '@msdyn365-commerce/components';
import { IComponentProps, IGridSettings, IImageSettings, TelemetryEvent } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import {
    AttributeValue, ProductAvailableQuantity, ProductDimension, ProductPrice, SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import classnames from 'classnames';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { NotifyMe } from '../../../../components/citta-notify-me/notify-me';
import { submitPdpEnquiryFormAction, SubmitPdpEnquiryFormDataActionInput } from '../../../../data-actions/pdp-enquiry/submit-pdp-enquiry-form-data';
import { IBuyboxProps as IBuyboxExtentionProps } from '../../definition-extensions/buybox.ext.props.autogenerated';
// import Input from 'reactstrap/lib/Input';


enum SOH {
    stockAvailability = 'StockAvailability',
    stockThreshold = 'StockThreshold',
    estimatedDate = 'EstimatedDate',
    soldOut = 'Sold Out',
    comingSoon = 'Coming Soon',
    inStock = 'In Stock',
    notifyMe = 'NOTIFY ME',
    expectedinStock = 'Expected in Stock:',
    enquireNow = 'ENQUIRE NOW'
}

export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; price?: ProductPrice; productDimensions?: ProductDimensionFull[]; productAvailableQuantity: IProductInventoryInformation[] | undefined }> {
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;

    imageSettings?: IImageSettings;
    gridSettings?: IGridSettings;
    props: IBuyboxViewProps & IBuyboxExtentionProps<IBuyboxData>;
    isLoading?: boolean;
    isUpdatingDimension?: boolean;

    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string;
        headerItemOneText: string;
        headerItemFormatText: string;
        headerMessageText: string;
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
    };
    telemetryContent?: ITelemetryContent;

    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
    changeUpdatingDimension?(isUpdatingDimension: boolean): void;
}

export declare type ICartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED';
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

interface IAddToCartState {
    isOpen: boolean;
    responseReceived: boolean;
    notifyResponseSuccess: boolean;
    name: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    enquiryMessage: string;
    storePickerValue: string;
    storePickerText: string;
    nError: boolean;
    lNError: boolean;
    eAError: boolean;
    pHError: boolean;
    reqError: boolean;
    eAValError: boolean;
    enquiryMessageError: boolean;
    cHError: boolean;
    nLSError: boolean;
    subcribeNewsletterChecked: boolean;
    customizationsCheckedItems: string[];
    productTypePickerValue: string;
    disabled: boolean;
    modalOpen: boolean;
    isNotifyOpen: boolean;
    addToCartButtonText: string;
    productsAddedToCart: number[];
}
/**
 *
 * The AddToCartComponent renders the AddToCartComponent section.
 * @extends {React.Component<IAddToCartComponentProps>}
 */
@observer
class AddToCartComponent extends React.Component<IAddToCartComponentProps, IAddToCartState> {
    private _productAttributeInStoreOnly: string | undefined;
    private response: boolean = false;
    private _nameInputRef: React.RefObject<HTMLInputElement>;
    private _lastNameInputRef: React.RefObject<HTMLInputElement>;
    private _emailAddressInputRef: React.RefObject<HTMLInputElement>;
    private _phoneNumberInputRef: React.RefObject<HTMLInputElement>;
    private _enquiryMessageInputRef: React.RefObject<HTMLTextAreaElement>;
    private _storePickerSelectRef: React.RefObject<HTMLSelectElement>;
    private _productTypePickerSelectRef: React.RefObject<HTMLSelectElement>;
    private requiredErrorMessage: string | undefined;
    private eAValidationErrorMessage: string | undefined;

    private nameValue: boolean = false;
    private lastNameValue: boolean = false;
    private emailAddressValue: boolean = false;
    private phoneNumberValue: boolean = false;
    private addressMessageValue: boolean = false;

    private formInputValues: boolean = false;

    // ProductDimensionType 1:Color 3:Size refer ProductDimensionType enum in @msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g
    private colorProductDimensionTypeValue: number = 1;
    private sizeProductDimensionTypeValue: number = 3;

    constructor(props: IAddToCartComponentProps, state: IAddToCartState) {
        super(props);
        this.state = {
            isOpen: false,
            responseReceived: false,
            notifyResponseSuccess: false,
            name: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            enquiryMessage: '',
            storePickerValue: '',
            storePickerText: 'Città BLOC',
            nError: false,
            lNError: false,
            eAError: false,
            pHError: false,
            reqError: false,
            eAValError: false,
            enquiryMessageError: false,
            cHError: false,
            nLSError: false,
            subcribeNewsletterChecked: false,
            customizationsCheckedItems: [],
            productTypePickerValue: '',
            disabled: false,
            modalOpen: false,
            isNotifyOpen: false,
            addToCartButtonText: this.props.addToCartText,//this.props.props.resources.addToCartText || 'Add to Cart', //HSO DS Changed Add to cart text from static to dynamic
            productsAddedToCart: []
        };
        this._productAttributeInStoreOnly = this.props.props.config.inStoreOnlyValue && this.props.props.config.inStoreOnlyValue.toUpperCase() || 'IN STORE ONLY';

        this._nameInputRef = React.createRef();
        this._lastNameInputRef = React.createRef();
        this._emailAddressInputRef = React.createRef();
        this._phoneNumberInputRef = React.createRef();
        this._enquiryMessageInputRef = React.createRef();
        this._storePickerSelectRef = React.createRef();
        this._productTypePickerSelectRef = React.createRef();
        this._closeModal = this._closeModal.bind(this);
        this._openModal = this._openModal.bind(this);
        this._handleSend = this._handleSend.bind(this);
        this._onClickHandler = this._onClickHandler.bind(this);
        this._onClick = this._onClick.bind(this);
        this._propogateResult = this._propogateResult.bind(this);
        this._propogateError = this._propogateError.bind(this);
        this._getLinkText = this._getLinkText.bind(this);
        this._addToCartError = this._addToCartError.bind(this);
        this._shouldShowOutOfStock = this._shouldShowOutOfStock.bind(this);
        this._isIntermediateState = this._isIntermediateState.bind(this);
        this._openNotifyModal = this._openNotifyModal.bind(this);
        this._closeNotifyModal = this._closeNotifyModal.bind(this);
        this._submitNotify = this._submitNotify.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        // @ts-ignore: Compiler not reconizing condition check for function params
        reaction(
            () => this.props.props.data.product.result ? this.props.props.data.product.result.RecordId : null,
            () => {
                if (this.props.context && this.props.props.data.product.result) {
                    const isProductAddedToCartBefore = this.state.productsAddedToCart.indexOf(this.props.props.data.product.result.RecordId) >= 0;
                    if (isProductAddedToCartBefore) {
                        this.setState({ addToCartButtonText: this.props.props.resources.addedToCartText || 'Added to Cart' });
                    } else {
                        this.setState({ addToCartButtonText: this.props.props.resources.addToCartText || 'Add to Cart' });
                    }
                }
            }
        );
    }

    public render(): JSX.Element | null {
        const priceComponent = this.props.data.price ? (
            <PriceComponent
                data={{ price: this.props.data.price }}
                context={this.props.context}
                id={this.props.id}
                typeName={this.props.typeName}
                freePriceText={this.props.dialogStrings?.freePriceText}
                originalPriceText={this.props.dialogStrings?.originalPriceText}
                currentPriceText={this.props.dialogStrings?.currentPriceText}
            />) : '';

        const popupProps: IPopupProps = {
            context: this.props.context,
            className: 'msc-add-to-cart',
            id: this.props.id,
            typeName: this.props.typeName,
            data: { product: this.props.data.product, price: this.props.data.price },
            dialogStrings: this.props.dialogStrings,
            imageSettings: this.props.imageSettings,
            gridSettings: this.props.context.request.gridSettings,
            productQuantity: this.props.quantity !== undefined ? this.props.quantity : 1,
            priceComponent: priceComponent,
            navigationUrl: this.props.navigationUrl,
            modalOpen: this.state.modalOpen,
            setModalOpen: (isModalOpen: boolean): void => {
                this.setState({ modalOpen: isModalOpen });
            }
        };

        const renderModalPopup = <Popup {...popupProps} />;
        const label = this._getLinkText(this.props);
        const payload = getPayloadObject(TelemetryEvent.AddToCart, this.props.telemetryContent!, label, '');
        const attributes = getTelemetryAttributes(this.props.telemetryContent!, payload);
        const isInStoreOnly = this._getProductAttributes(this._productAttributeInStoreOnly);

        const activateNotifyModal = this.props.props.config.activateNotifyModal;
        return (
            <>
                {renderModalPopup}
                {isInStoreOnly ?
                    <Button
                        className='msc-add-to-cart'
                        title={SOH.enquireNow}
                        color='primary'
                        onClick={this._openModal} // or open notify depending on status
                    >
                        {SOH.enquireNow}
                    </Button> :
                    <Button
                        className={classnames('msc-add-to-cart ', this.props.className)}
                        title={this._getLinkAriaLabel(this.props)}
                        {...attributes}
                        color='primary'
                        onClick={this._getLinkText(this.props) === this.state.addToCartButtonText ? this._onClickHandler : (activateNotifyModal ? this._openNotifyModal : this._openModal)}
                        disabled={this.props.disabled || this.state.disabled || this._isIntermediateState(this.props) || this._shouldShowOutOfStock(this.props, false)}
                    >
                        {this._getLinkText(this.props)}
                    </Button>
                }
                {this._renderEnquiremodal()}
                {activateNotifyModal && this._renderNotifyModal()}
            </>
        );
    }

    private async _onClickHandler(event: React.MouseEvent<HTMLElement>): Promise<void> {
        await this._onClick(event, this.props);
    }

    private async _onClick(_event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps): Promise<void> {
        const cartError = this._addToCartError(props);
        let productToAdd = props.data.product;

        if (cartError) {
            this._propogateError(props, cartError);
            return;
        }

        if (!(props.getSelectedProduct === undefined)) {
            productToAdd = (await props.getSelectedProduct) || props.data.product;
        }

        const productsAddedToCart = this.state.productsAddedToCart;
        const productId = productToAdd && productToAdd.RecordId;

        const cartState = await getCartState(props.context.actionContext);


        //HSO DS
        let productAvailability = props.productAvailability?.AvailableQuantity;
        let stockcheck = props.context.app.config.enableStockCheck;
        const HSOConfigprop = props.data.productAvailableQuantity
            && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'HSOConfig'));

        const HSOConfigpropval = HSOConfigprop && HSOConfigprop.Value && HSOConfigprop.Value.IntegerValue;
        let HSOConfig = 0;
        if (HSOConfigpropval != undefined) {
            HSOConfig = HSOConfigpropval;
        }
        if (HSOConfig == 1) {

            const StockThresholdprop = props.data.productAvailableQuantity
                && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
                && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'StockThreshold'));

            const StockThresholdpropval = StockThresholdprop && StockThresholdprop.Value && StockThresholdprop.Value.IntegerValue;
            let StockThreshold = 0;
            if (StockThresholdpropval != undefined) {
                StockThreshold = StockThresholdpropval;
            }

            const onhandpropval = props.data.productAvailableQuantity
                && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                && props.data.productAvailableQuantity[0].ProductAvailableQuantity.AvailableQuantity
            let onhand = 0;
            if (onhandpropval != undefined) {
                onhand = Number(onhandpropval);
            }

            const estimatedDateProperty = props.data.productAvailableQuantity
                && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
                && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'EstimatedDate'));

            const estimatedDateValue = estimatedDateProperty && estimatedDateProperty.Value && estimatedDateProperty.Value.StringValue;
            let estimatedDate = "";
            if (estimatedDateValue == undefined) {
                estimatedDate = "";
            } else {
                estimatedDate = estimatedDateValue;
            }

            if (StockThreshold != 0 && onhand < StockThreshold && estimatedDate != "") {
                productAvailability = 1;
                stockcheck = false;
            }
        }
        await cartState.addProductToCart({
            product: productToAdd, additionalProperties: { orderQuantityLimitsFeatureIsEnabled: true },
            count: props.quantity, availableQuantity: productAvailability, enableStockCheck: stockcheck
        })
            .then(result => {
                if (result.status === 'SUCCESS') {
                    if (props.dialogStrings && props.context.app.config.addToCartBehavior === 'showModal') {
                        this.setState({
                            disabled: false,
                            modalOpen: true
                        });
                    }
                    if (productId) {
                        productsAddedToCart.push(productId);
                    }
                    this.setState({
                        addToCartButtonText: this.props.addToCartText, //this.props.props.resources.addedToCartText || 'Added to Cart', HSO DS
                        productsAddedToCart: productsAddedToCart
                    });
                }
            });
    }

    private _propogateResult(props: IAddToCartComponentProps, result: ICartActionResult): void {
        if (props.onAdd) {
            props.onAdd(result);
        }
    }

    private _propogateError(props: IAddToCartComponentProps, result: IAddToCartFailureResult): void {
        if (props.onError) {
            props.onError(result);
        }
    }

    private _getLinkText(props: IAddToCartComponentProps): string {
        if (props.data.productAvailableQuantity
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties) {
            const stockAvailabilityProperty = props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === SOH.stockAvailability));
            if ((stockAvailabilityProperty && stockAvailabilityProperty.Value?.StringValue === SOH.soldOut) || stockAvailabilityProperty === undefined) {
                return props.outOfStockText;
            } else if (stockAvailabilityProperty && stockAvailabilityProperty.Value?.StringValue === SOH.comingSoon) {
                //HSO DS

                const HSOConfigprop = props.data.productAvailableQuantity
                    && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                    && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
                    && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'HSOConfig'));

                const HSOConfigpropval = HSOConfigprop && HSOConfigprop.Value && HSOConfigprop.Value.IntegerValue;
                let HSOConfig = 0;
                if (HSOConfigpropval != undefined) {
                    HSOConfig = HSOConfigpropval;
                }
                if (HSOConfig == 1) {
                    const StockThresholdprop = props.data.productAvailableQuantity
                        && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                        && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
                        && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'StockThreshold'));

                    const StockThresholdpropval = StockThresholdprop && StockThresholdprop.Value && StockThresholdprop.Value.IntegerValue;
                    let StockThreshold = 0;
                    if (StockThresholdpropval != undefined) {
                        StockThreshold = StockThresholdpropval;
                    }

                    const onhandpropval = props.data.productAvailableQuantity
                        && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                        && props.data.productAvailableQuantity[0].ProductAvailableQuantity.AvailableQuantity
                    let onhand = 0;
                    if (onhandpropval != undefined) {
                        onhand = Number(onhandpropval);
                    }

                    const estimatedDateProperty = props.data.productAvailableQuantity
                        && props.data.productAvailableQuantity && props.data.productAvailableQuantity[0].ProductAvailableQuantity
                        && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
                        && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'EstimatedDate'));

                    const estimatedDateValue = estimatedDateProperty && estimatedDateProperty.Value && estimatedDateProperty.Value.StringValue;
                    let estimatedDate = "";
                    if (estimatedDateValue == undefined) {
                        estimatedDate = "";
                    } else {
                        estimatedDate = estimatedDateValue;
                    }

                    if (StockThreshold != 0 && onhand < StockThreshold && estimatedDate != "") {
                        return this.state.addToCartButtonText;

                    } else {
                        return SOH.notifyMe;
                    }
                } else {
                    return SOH.notifyMe;
                }

            } else {
                return this.state.addToCartButtonText;
            }
        }

        if (!this._shouldShowOutOfStock(props, false)) {
            return this.state.addToCartButtonText;
        }

        return props.outOfStockText;
    }

    private _getLinkAriaLabel(props: IAddToCartComponentProps): string {
        if (props.data.productAvailableQuantity
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity
            && props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties) {
            const stockAvailabilityProperty = props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === SOH.stockAvailability));
            if ((stockAvailabilityProperty && stockAvailabilityProperty.Value?.StringValue === SOH.soldOut) || stockAvailabilityProperty === undefined) {
                return props.outOfStockText;
            } else if (stockAvailabilityProperty && stockAvailabilityProperty.Value?.StringValue === SOH.comingSoon) {
                return this.state.addToCartButtonText;// return SOH.notifyMe;  HSO DS
            } else {
                return this.state.addToCartButtonText;
            }
        }

        if (!this._shouldShowOutOfStock(props, false)) {
            return this.state.addToCartButtonText;
        }

        return props.outOfStockText;
    }

    private _addToCartError(props: IAddToCartComponentProps): IAddToCartFailureResult | undefined {
        if (!props.data || !props.data.product.RecordId) {
            // No product exists, won't be able to add to cart
            return { failureReason: 'EMPTYINPUT' };
        }

        if (props.data.product.Dimensions) {
            const missingDimensions = props.data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

            if (missingDimensions.length > 0) {
                // At least one dimension with no value exists on the product, won't be able to add to cart
                return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
            }
        }

        if (this._shouldShowOutOfStock(props, true)) {
            const availableQuantity = (props.productAvailability && props.productAvailability.AvailableQuantity) || 0;
            const stockLeft = Math.max(availableQuantity, 0);

            return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
        }

        // Only allow adding to cart if not showing out of stock
        return undefined;
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private _shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
        if (this.props.data.productAvailableQuantity
            && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity
            && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties) {
            const stockAvailabilityProperty = this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === SOH.stockAvailability));
            return (stockAvailabilityProperty && stockAvailabilityProperty.Value?.StringValue === SOH.soldOut) || stockAvailabilityProperty === undefined;
        }

        if (this.props.data.productAvailableQuantity
            && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity
            && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties) {
            const stockThresholdProperty = this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === SOH.stockThreshold));
            const stockThreshold = stockThresholdProperty && stockThresholdProperty?.Value?.IntegerValue || this.props.context.app.config.outOfStockThreshold;
            if (this.props.data.productAvailableQuantity
                && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity
                && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.AvailableQuantity !== undefined &&
                // tslint:disable-next-line: restrict-plus-operands
                this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.AvailableQuantity >= stockThreshold + 1) {
                return false;
            }
        }

        if (props.context.app.config.enableStockCheck === undefined || props.context.app.config.enableStockCheck === false || props.isLoading || props.isUpdatingDimension) {
            // Out of stock turn off, don't bother showing out of stock
            return false;
        }

        if (!props.data || !props.data.product.RecordId) {
            // No product exists, don't bother showing out of stock
            return false;
        }

        if (props.data.product.Dimensions) {
            if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
                // At least one dimension with no value exists on the product, so also don't show out of stock
                return false;
            }
        }
        const includedQuantityNumber = includeCurrentQuantity && props.quantity ? props.quantity : 1;

        return (props.productAvailability
            && props.productAvailability.AvailableQuantity !== undefined
            && props.productAvailability.AvailableQuantity <= includedQuantityNumber || false);
    };

    private _isIntermediateState(props: IAddToCartComponentProps): boolean {
        if (props.data.product.Dimensions) {
            if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
                // At least one dimension with no value exists on the product, so also not in intermediate state
                return false;
            }
        }

        if (!props.isLoading && !props.isUpdatingDimension) {
            return false;
        }

        return true;
    }

    private _renderNotifyModal(): JSX.Element {
        const { config, resources } = this.props.props;
        const { responseReceived, notifyResponseSuccess } = this.state;

        return (
            <div>
                <Modal
                    autoFocus={config.autoFocus}
                    fade={config.fade}
                    isOpen={this.state.isNotifyOpen}
                    zIndex={config.zIndex}
                    toggle={this._closeNotifyModal}
                    applicationNode={'rsg-root'}
                    className={'notify-me-modal'}
                >
                    <ModalHeader toggle={this._closeNotifyModal}>{config.notifyModalHeader}</ModalHeader>

                    <ModalBody>
                        {responseReceived && notifyResponseSuccess ?
                            <div className={'notify-form-success '}>
                                <div className={'notify-form-success__header'}>{config.notifyModalSuccessHeader}</div>

                                <div className={'notify-form-success__message'}>{config.notifyModalSuccessMessage}</div>
                            </div>
                            :
                            <NotifyMe
                                notifyFormText={resources.notifyFormText}
                                firstNamePlaceholderText={resources.notifyFirstNamePlaceholderText}
                                lastNamePlaceholderText={resources.notifyLastNamePlaceholderText}
                                emailAddressPlaceholderText={resources.notifyEmailAddressPlaceholderText}
                                notifyContactMessage={config.notifyContactMessage!}
                                notifyContactMessageLink={config.notifyContactMessageLink!}
                                sendButtonText={resources.notifySendButtonText}
                                requiredTextErrorMessage={resources.requiredTextErrorMessage}
                                eAValidationErrorMessage={resources.eAValidationErrorMessage}
                                notifySubmitClicked={this._submitNotify}
                            />
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    private _closeNotifyModal(): void {
        this.setState(prevState => ({
            isNotifyOpen: !prevState.isNotifyOpen,
            notifyResponseSuccess: false,
            responseReceived: false
        }));
    }
    private _openNotifyModal(): void {
        this.setState(prevState => ({
            isNotifyOpen: !prevState.isNotifyOpen,
            notifyResponseSuccess: false,
            responseReceived: false
        }));
    }

    private async _submitNotify(fName: string, lName: string, email: string, subscribeChecked: boolean): Promise<void> {
        const { context } = this.props;

        // @ts-ignore
        const market = context.request.channel.ChannelNaturalId;

        const product = this.props.data.product;

        const pID = product.ItemId || '';
        // const { config } = this.props.props;
        // let response = await MsDyn365.commerceApiRequest(
        //     this.props.context.request,
        //     'PostNotifyMe',
        //     'post',
        //     {
        //         firstName: fName,
        //         lastName: lName,
        //         emailAddress: email,
        //         optIn: subscribeChecked,
        //         itemId: pID,
        //         country: config.notifyCountry,
        //         emarsysGroupId: config.notifyEmarsysGroup,
        //         emarsysSkuField: config.notifyEmarsysSkuField
        //     }
        // ) as any;

        // if (response?.data.Result !== 'Error') {
        //     this.setState({ responseReceived: true, notifyResponseSuccess: true });
        // }

        // dont post to AX at the moment
        this.formInputValues = (fName && lName && email) ? true : false;

        if (this.formInputValues) {
            let productImageUrl = product.PrimaryImageUrl;
            let url = window.location.href;

            try {
                const price = !isNaN(product.Price) ? product.Price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : product.Price.toString();
                const result = await submitPdpEnquiryFormAction(new SubmitPdpEnquiryFormDataActionInput(fName, lName, email, price, '', pID, 'Notify when back in stock', url, productImageUrl!, product.Name || '', 'Notify when back in stock', market || 'unknown', 0), this.props.context.actionContext);
                this.response = result.success;
                this.setState({ responseReceived: true, notifyResponseSuccess: true });
            } catch {
                console.log('Please fill the form!');
            }
        }
    }

    // tslint:disable: max-func-body-length
    private _renderEnquiremodal(): JSX.Element {
        const { config, resources } = this.props.props;
        const cittaStoresList: string = this.props.context.app.config.cittaStoresList || 'Città BLOC,000010|Città Grey Lynn,000012|Città Hamilton,000013|Città Merivale,000016|Città Wellington,000017|Città Wellington Thorndon,000018|Città Newmarket,000041|Città Takapuna,000044';
        const cittaStores: string[] = cittaStoresList.split('|');

        const colors = this._getDimensionValues(this.props.data.productDimensions || [], this.colorProductDimensionTypeValue);
        const sizes = this._getDimensionValues(this.props.data.productDimensions || [], this.sizeProductDimensionTypeValue);

        //HSO DS

        const HSOConfigprop = this.props.data.productAvailableQuantity
        && this.props.data.productAvailableQuantity && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity
        && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
        && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'HSOConfig'));

    const HSOConfigpropval = HSOConfigprop && HSOConfigprop.Value && HSOConfigprop.Value.IntegerValue;
    let HSOConfig = 0;
    if (HSOConfigpropval != undefined) {
        HSOConfig = HSOConfigpropval;
    }
        return (
            <div>
                <Modal
                    autoFocus={config.autoFocus}
                    fade={config.fade}
                    isOpen={this.state.isOpen}
                    zIndex={config.zIndex}
                    toggle={this._closeModal}
                    applicationNode={'rsg-root'}
                    className={'enquire-now-modal'}
                >
                    <ModalHeader toggle={this._closeModal}>{(HSOConfig == 1) ? 'product enquiry' : ( config.modalHeader || 'enquire in-store')}</ModalHeader>
                    <ModalBody>
                        <form className='enquire-form'>
                            {this.state.responseReceived && this.response
                                ? <div className='response-message'>
                                    <p className='response-message-content'>{resources.responseSuccessMessage}</p>
                                </div> : null}
                            {this.state.responseReceived && !this.response ? <div className='response-message'>
                                <p className='response-error-message-content'>{resources.responseErrorMessage}</p>
                            </div> : null}
                            {!this.state.responseReceived ? <div>
                                <div className='storepicker-dropdown'>
                                    <label id='store-picker__label' />
                                    <select
                                        ref={this._storePickerSelectRef}
                                        className='store-picker-text'
                                        value={ (HSOConfig == 1) ? 'Città B2B' :  this.state.storePickerValue}
                                        onChange={this.onChangeSelect}
                                        aria-label='Store Picker'
                                        aria-labelledby='store-picker__label store-picker__error'
                                    >
                                        {
                                            (HSOConfig == 1) ? <option value="">Città B2B</option> :
                                            cittaStores.map((storeData, index) => {
                                                const store = storeData.split(',');
                                                return (
                                                    <option value={store && store[1] || ''} key={index} selected={index === 0 && true || false} aria-selected={index === 0 && true || false}>{store && store[0] || ''}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='enquire-form-name'>
                                    {this.state.nError ? this._renderAlert('contact-form-name') : ''}
                                    <label id='enquire-form-name__label' />
                                    <input
                                        ref={this._nameInputRef}
                                        type='text'
                                        onChange={this.handleChange('nameInput')}
                                        className='enquire-form-name-text'
                                        aria-label={resources.enquireNamePlaceholderText}
                                        value={this.state.name}
                                        placeholder={resources.enquireNamePlaceholderText}
                                        aria-labelledby='enquire-form-name__label enquire-form-name__error'
                                    />
                                </div>
                                <div className='enquire-form-lastname'>
                                    {this.state.lNError ? this._renderAlert('contact-form-name') : ''}
                                    <label id='enquire-form-lastname__label' />
                                    <input
                                        ref={this._lastNameInputRef}
                                        type='text'
                                        onChange={this.handleChange('lastNameInput')}
                                        className='enquire-form-lastname-text'
                                        aria-label={resources.enquireLastNamePlaceholderText}
                                        value={this.state.lastName}
                                        placeholder={resources.enquireLastNamePlaceholderText}
                                        aria-labelledby='enquire-form-lastname__label enquire-form-lastname__error'
                                    />
                                </div>
                                <div className='enquire-form-emailaddress'>
                                    {this.state.eAError || this.state.eAValError ? this._renderAlert('contact-form-emailaddress') : ''}
                                    <label id='enquire-form-emailaddress__label' />
                                    <input
                                        ref={this._emailAddressInputRef}
                                        type='text'
                                        onChange={this.handleChange('emailAddress')}
                                        className='enquire-form-emailaddress-text'
                                        aria-label={resources.enquireEmailAddressPlaceholderText}
                                        value={this.state.emailAddress}
                                        placeholder={resources.enquireEmailAddressPlaceholderText}
                                        aria-labelledby='enquire-form-emailaddress__label enquire-form-emailaddress__error'
                                    />
                                </div>
                                <div className='enquire-form-phonenumber'>
                                    {this.state.pHError ? this._renderAlert('contact-form-phonenumber') : ''}
                                    <label id='enquire-form-phonenumber__label' />
                                    <input
                                        ref={this._phoneNumberInputRef}
                                        type='tel'
                                        onChange={this.handleChange('phoneNumber')}
                                        className='enquire-form-phonenumber-text'
                                        aria-label={resources.enquirePhoneNumberPlaceholderText}
                                        value={this.state.phoneNumber}
                                        placeholder={resources.enquirePhoneNumberPlaceholderText}
                                        aria-labelledby='enquire-form-phonenumber__label enquire-form-phonenumber__error'
                                    />
                                </div>
                                {sizes && (
                                    <div className='enquire-customizations'>
                                        <label className='enquire-customizations-heading'>{resources.customisationsLabel}</label>
                                        <div className='enquire-customizations-types'>
                                            {
                                                sizes.map((size, index) => {
                                                    return (
                                                        <div className='c-fauxCheckbox' key={index}>
                                                            <input
                                                                className='fauxRadio'
                                                                type='checkbox'
                                                                aria-checked='false'
                                                                id={size}
                                                                value={size}
                                                                onChange={this.customizationOnChange(size)}
                                                            />
                                                            <label className='fauxRadio' tabIndex={0} htmlFor={size} />
                                                            <label>{size}</label>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        {this.state.cHError ? this._renderAlert('contact-form-customizations') : ''}
                                    </div>
                                )}
                                {colors && (
                                    <div className='enquire-product-type'>
                                        <div className='product-type-dropdown'>
                                            <label id='enquire-product-type__label' />
                                            <select
                                                ref={this._productTypePickerSelectRef}
                                                className='store-picker-text'
                                                value={this.state.productTypePickerValue}
                                                onChange={this.productTypeOnChangeSelect}
                                                aria-label='Enquire Product Type'
                                                aria-labelledby='enquire-product-type__label store-picker__error'
                                            >
                                                {
                                                    colors.map((color, index) => {
                                                        return (
                                                            <option value={color} key={index} selected={index === 0 && true || false} aria-selected={index === 0 && true || false}>{color}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )}
                                <div className='enquire-form-message'>
                                    {this.state.enquiryMessageError ? this._renderAlert('enquire-form-message') : ''}
                                    <label id='enquire-form-message__label' />
                                    <textarea
                                        ref={this._enquiryMessageInputRef}
                                        onChange={this.handleMessageChange}
                                        className='enquire-form-message-text'
                                        aria-label={resources.enquireMessagePlaceholderText}
                                        value={this.state.enquiryMessage}
                                        placeholder={resources.enquireMessagePlaceholderText}
                                        aria-labelledby='enquire-form-message__label enquire-form-message__error'
                                    />
                                </div>
                                <div className='enquire-form-send'>
                                    <Button
                                        className='enquire-form-send-button'
                                        title={resources.enquireSendButtonText}
                                        color='primary'
                                        onClick={this._handleSend}
                                        aria-label={resources.enquireSendButtonText}
                                    >
                                        {resources.enquireSendButtonText}
                                    </Button>

                                    {(HSOConfig == 1) ? null : <a href='/stores' target='_blank' role='link' className='store-link'>Store Locations</a>}
                                </div>
                            </div>
                                : null}
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    private handleChange = (inputText: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        switch (inputText) {
            case 'nameInput': {
                this.setState({ name: value, reqError: false, nError: false, eAValError: false, nLSError: false });
                break;
            }
            case 'lastNameInput': {
                this.setState({ lastName: value, reqError: false, lNError: false, eAValError: false, nLSError: false });
                break;
            }
            case 'emailAddress': {
                this.setState({ emailAddress: value, reqError: false, eAError: false, eAValError: false, nLSError: false });
                break;
            }
            case 'phoneNumber': {
                this.setState({ phoneNumber: value, reqError: false, pHError: false, eAValError: false, nLSError: false });
                break;
            }
            case 'subsNwsltr': {
                this.setState({ subcribeNewsletterChecked: true, reqError: false, pHError: false, eAValError: false, nLSError: false });
                break;
            }
            default: null;
        }
    }

    private handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        this.setState({ enquiryMessage: value, reqError: false, eAValError: false, nLSError: false });
    }

    private productTypeOnChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ productTypePickerValue: e.target.value || '' });
    };

    private customizationOnChange = (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        let customizationsCheckedItems = this.state.customizationsCheckedItems;
        if (isChecked) {
            customizationsCheckedItems.push(value);
        } else {
            customizationsCheckedItems = customizationsCheckedItems.filter(item => item !== value);
        }
        this.setState({ customizationsCheckedItems: customizationsCheckedItems, cHError: false });
    }

    private onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const text = e.target.options[e.target.options.selectedIndex].textContent;
        this.setState({ storePickerValue: e.target.value || '', storePickerText: text || '' });
    };

    private _closeModal(): void {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }
    private _openModal(): void {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            responseReceived: false
        }));
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private async _handleSend(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | React.FormEvent<HTMLFormElement>): Promise<void> {
           //HSO DS

           const HSOConfigprop = this.props.data.productAvailableQuantity
           && this.props.data.productAvailableQuantity && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity
           && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties
           && this.props.data.productAvailableQuantity[0].ProductAvailableQuantity.ExtensionProperties.find((property => property.Key === 'HSOConfig'));
   
       const HSOConfigpropval = HSOConfigprop && HSOConfigprop.Value && HSOConfigprop.Value.IntegerValue;
       let HSOConfig = 0;
       if (HSOConfigpropval != undefined) {
           HSOConfig = HSOConfigpropval;
       }
        this.nameValue = (this._nameInputRef && this._nameInputRef.current && this._nameInputRef.current.value) ? true : false;
        this.lastNameValue = (this._lastNameInputRef && this._lastNameInputRef.current && this._lastNameInputRef.current.value) ? true : false;
        this.emailAddressValue = (this._emailAddressInputRef && this._emailAddressInputRef.current && this._emailAddressInputRef.current.value) ? true : false;
        this.phoneNumberValue = (this._phoneNumberInputRef && this._phoneNumberInputRef.current && this._phoneNumberInputRef.current.value) ? true : false;
        this.addressMessageValue = (this._enquiryMessageInputRef && this._enquiryMessageInputRef.current && this._enquiryMessageInputRef.current.value) ? true : false;

        const sP = (HSOConfig == 1) ? 'Città B2B' : this.state.storePickerText;
        const nA = (this._nameInputRef && this._nameInputRef.current && this._nameInputRef.current.value) ? this._nameInputRef.current.value : '';
        const lN = (this._lastNameInputRef && this._lastNameInputRef.current && this._lastNameInputRef.current.value) ? this._lastNameInputRef.current.value : '';
        const eA = (this._emailAddressInputRef && this._emailAddressInputRef.current && this._emailAddressInputRef.current.value) ? this._emailAddressInputRef.current.value : '';
        const pN = (this._phoneNumberInputRef && this._phoneNumberInputRef.current && this._phoneNumberInputRef.current.value) ? this._phoneNumberInputRef.current.value : '';
        const pT = (this._productTypePickerSelectRef && this._productTypePickerSelectRef.current && this._productTypePickerSelectRef.current.value) ? this._productTypePickerSelectRef.current.value : '';
        const eM = (this._enquiryMessageInputRef && this._enquiryMessageInputRef.current && this._enquiryMessageInputRef.current.value) ? this._enquiryMessageInputRef.current.value : '';
        const cU = this.state.customizationsCheckedItems.toString();
        const cI = this._storePickerSelectRef.current!.value;
        const eAVal = this._emailAddressInputRef.current!.value;
        const pID = this.props.data.product.ItemId || '';
        const sN = this.state.subcribeNewsletterChecked ? 1 : 0;

        if (!this.nameValue) {
            this.setState({
                nError: true,
                reqError: true
            });
        } else if (!this.lastNameValue) {
            this.setState({
                lNError: true,
                reqError: true
            });
        } else if (!this.emailAddressValue) {
            this.setState({
                eAError: true,
                reqError: true
            });
        } else if (!this.isEmailValid(eAVal)) {
            this.setState({
                eAValError: true
            });
        } else if (!this.phoneNumberValue) {
            this.setState({
                pHError: true,
                reqError: true
            });
        } else if (this.state.customizationsCheckedItems.length < 1) {
            this.setState({
                cHError: true,
                reqError: true
            });
        } else if (!this.addressMessageValue) {
            this.setState({
                enquiryMessageError: true,
                reqError: true
            });
        } else {
            this.formInputValues = (this.nameValue && this.lastNameValue && this.emailAddressValue && this.phoneNumberValue && this.addressMessageValue) ? true : false;

            if (this.formInputValues && !this.state.eAValError) {
                try {
                    const result = await submitPdpEnquiryFormAction(new SubmitPdpEnquiryFormDataActionInput(nA, lN, eA, pN, sP, pID, eM, cU, pT, '', 'Enquire in Store', cI, sN), this.props.context.actionContext);
                    this.response = result.success;
                    this.setState({ responseReceived: true });
                } catch {
                    console.log('Please fill the form!');
                }
            }
        }
    }

    private isEmailValid = (email: string): boolean => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    };

    private _renderAlert(className: string): JSX.Element {
        const inputBoxErrors = (this.state.nError || this.state.lNError || this.state.eAError || this.state.pHError || this.state.cHError || this.state.enquiryMessageError);
        if (inputBoxErrors) {
            this.requiredErrorMessage = this.props.props.resources.requiredTextErrorMessage;
        }
        if (this.state.emailAddress !== '' && this.state.eAValError) {
            this.eAValidationErrorMessage = this.props.props.resources.eAValidationErrorMessage;
        }

        const reqError = this.state.reqError;
        const eAValError = this.state.eAValError;
        return (
            <>
                {reqError && (
                    <span className={`${className}__alert`} role='alert' aria-live='assertive'>
                        {
                            this.requiredErrorMessage &&
                            <>
                                <span className={`${className}__alert-icon`} aria-hidden='true' />
                                {this.requiredErrorMessage}
                            </>
                        }
                    </span>
                )}
                {eAValError && (
                    <span className={`${className}__alert`} role='alert' aria-live='assertive'>
                        {
                            this.eAValidationErrorMessage &&
                            <>
                                <span className={`${className}__alert-icon`} aria-hidden='true' />
                                {this.eAValidationErrorMessage}
                            </>
                        }
                    </span>
                )}
            </>
        );
    }

    private _getProductAttributes = (key: string | undefined): boolean => {
        // @ts-ignore
        const prodAttributes: AttributeValue[] | undefined = this.props && this.props.data && this.props.props.data && this.props.props.data.productAttributes.result;
        if (prodAttributes && prodAttributes.length) {
            const filterAttributes = prodAttributes.filter(
                attribute => attribute.Name && attribute.Name.toUpperCase() === key
            );
            if (
                filterAttributes &&
                filterAttributes.length) {
                filterAttributes[0].Name?.toUpperCase() === key ? true : false;
                return filterAttributes[0].BooleanValue || false;
            }
            return false;
        }
        return false;
    }

    private _getDimensionValues = (dimensions: ProductDimensionFull[], dimensionTypeValue: number): string[] => {
        if (dimensions && dimensionTypeValue) {
            const dimensionTypeValues: ProductDimensionFull[] = dimensions.filter(dimension => dimension.DimensionTypeValue === dimensionTypeValue);
            // tslint:disable-next-line: prefer-const
            let values: string[] = [];
            if (dimensionTypeValues) {
                dimensionTypeValues && dimensionTypeValues.forEach(elements => {
                    elements.DimensionValues && elements.DimensionValues.forEach(value => {
                        values.push(value.Value || '');
                    });
                });
                return values;
            }
        }
        return [];
    }
}

export default AddToCartComponent;
