
import { Button } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { INotifyMeProps } from './notify-me.props';
import { INotifyMeState } from './notify-me.state';

/**
 *
 * Notify Me component
 */
export class NotifyMe extends React.Component<INotifyMeProps, INotifyMeState> {
    private _firstNameInputRef: React.RefObject<HTMLInputElement>;
    private _lastNameInputRef: React.RefObject<HTMLInputElement>;
    private _emailAddressInputRef: React.RefObject<HTMLInputElement>;

    private requiredErrorMessage: string | undefined;
    private eAValidationErrorMessage: string | undefined;

    private firstNameValue: boolean = false;
    private lastNameValue: boolean = false;
    private emailAddressValue: boolean = false;

    constructor(props: INotifyMeProps) {
        super(props);
        this.state = {
            fNError: false,
            lNError: false,
            eAError: false,
            eAValError: false,
            subscribeChecked: false,
            firstName: '',
            lastName: '',
            emailAddress: ''
        };

        this._firstNameInputRef = React.createRef();
        this._lastNameInputRef = React.createRef();
        this._emailAddressInputRef = React.createRef();
        this._handleSend = this._handleSend.bind(this);
    }

    public render(): JSX.Element | null {
        const {
            notifyFormText,
            firstNamePlaceholderText,
            lastNamePlaceholderText,
            emailAddressPlaceholderText,
            notifyContactMessage,
            notifyContactMessageLink,
            sendButtonText
        } = this.props;

        return (
            <div>
                <form className='notify-form'>
                    <p className='notify-form-text'>
                        {notifyFormText}
                    </p>
                    <div className='notify-form-name'>
                        {this.state.fNError ? this._renderAlert('contact-notifyform-firstname') : ''}
                        <input
                            ref={this._firstNameInputRef}
                            type='text'
                            onChange={this.handleChange('firstName')}
                            className='notify-form-name-text'
                            aria-label={firstNamePlaceholderText}
                            value={this.state.firstName}
                            placeholder={firstNamePlaceholderText}
                            aria-labelledby='notify-form-firstname__label notify-form-firstname__error'
                            required
                        />
                    </div>
                    <div className='notify-form-name'>
                        {this.state.lNError ? this._renderAlert('contact-notifyform-lastname') : ''}
                        <input
                            ref={this._lastNameInputRef}
                            type='text'
                            onChange={this.handleChange('lastName')}
                            className='notify-form-name-text'
                            aria-label={lastNamePlaceholderText}
                            value={this.state.lastName}
                            placeholder={lastNamePlaceholderText}
                            aria-labelledby='notify-form-lastname__label notify-form-lastname__error'
                            required
                        />
                    </div>
                    <div className='notify-form-emailaddress'>
                        {this.state.eAError || this.state.eAValError ? this._renderAlert('contact-notifyform-emailaddress') : ''}
                        <label id='notify-form-emailaddress__label' />
                        <input
                            ref={this._emailAddressInputRef}
                            type='email'
                            onChange={this.handleChange('emailAddress')}
                            className='notify-form-emailaddress-text'
                            aria-label={emailAddressPlaceholderText}
                            value={this.state.emailAddress}
                            placeholder={emailAddressPlaceholderText}
                            aria-labelledby='notify-form-emailaddress__label notify-form-emailaddress__error'
                            required
                        />
                    </div>
                    <div className='notify-form-send'>
                        <Button
                            className='notify-form-send-button'
                            title={sendButtonText}
                            color='primary'
                            aria-label={sendButtonText}
                            onClick={this._handleSend}
                        >
                            {sendButtonText}
                        </Button>
                    </div>
                </form>
                <div>
                    <label className='contact-us-msg'>{notifyContactMessage} <a href={notifyContactMessageLink}>contact us here</a></label>
                </div>
            </div>
        );
    }

    private isEmailValid = (email: string): boolean => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    };

    private handleChange = (inputText: string) => (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> ) => {
        const value = e.target.value;
        switch (inputText) {
            case 'firstName': {
                this.setState({ firstName: value, reqError: false, fNError: false, lNError: false, eAError: false, eAValError: false });
                break;
            }
            case 'lastName': {
                this.setState({ lastName: value, reqError: false, fNError: false, lNError: false, eAError: false, eAValError: false });
                break;
            }
            case 'emailAddress': {
                this.setState({ emailAddress: value, reqError: false, fNError: false, lNError: false, eAError: false, eAValError: false });
                break;
            }
            case 'subscribe': {
                this.setState(prevState => ({subscribeChecked: !prevState.subscribeChecked }));
                break;
            }
            default: null;
        }
    }

    private _renderAlert(className: string): JSX.Element {
        const inputBoxErrors = (this.state.fNError || this.state.lNError || this.state.eAError);
        if (inputBoxErrors) {
            this.requiredErrorMessage = this.props.requiredTextErrorMessage;
        }
        if (this.state.emailAddress !== '' && this.state.eAValError) {
            this.eAValidationErrorMessage = this.props.eAValidationErrorMessage;
        }

        const reqError = this.state.reqError;
        const eAValError = this.state.eAValError;
        return (
            <>
                {reqError && (
                    <span className={`${className}__alert`} role='alert' aria-live='assertive'>
                        {
                            this.requiredErrorMessage &&
                            <>
                                <span className={`${className}__alert-icon`} aria-hidden='true' />
                                {this.requiredErrorMessage}
                            </>
                        }
                    </span>
                )}
                {eAValError && (
                    <span className={`${className}__alert`} role='alert' aria-live='assertive'>
                        {
                            this.eAValidationErrorMessage &&
                            <>
                                <span className={`${className}__alert-icon`} aria-hidden='true' />
                                {this.eAValidationErrorMessage}
                            </>
                        }
                    </span>
                )}
            </>
        );
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private async _handleSend(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | React.FormEvent<HTMLFormElement>): Promise<void> {
        this.firstNameValue = (this._firstNameInputRef && this._firstNameInputRef.current && this._firstNameInputRef.current.value) ? true : false;
        this.lastNameValue = (this._lastNameInputRef && this._lastNameInputRef.current && this._lastNameInputRef.current.value) ? true : false;
        this.emailAddressValue = (this._emailAddressInputRef && this._emailAddressInputRef.current && this._emailAddressInputRef.current.value) ? true : false;

        const fnA = (this._firstNameInputRef && this._firstNameInputRef.current && this._firstNameInputRef.current.value) ? this._firstNameInputRef.current.value : '';
        const lnA = (this._lastNameInputRef && this._lastNameInputRef.current && this._lastNameInputRef.current.value) ? this._lastNameInputRef.current.value : '';
        const eA = (this._emailAddressInputRef && this._emailAddressInputRef.current && this._emailAddressInputRef.current.value) ? this._emailAddressInputRef.current.value : '';
        const eAVal = this._emailAddressInputRef.current!.value;

        const { subscribeChecked } = this.state;

        if (!this.firstNameValue) {
            this.setState({
                fNError: true,
                reqError: true
            });
        } else if (!this.lastNameValue) {
            this.setState({
                lNError: true,
                reqError: true
            });
        } else if (!this.emailAddressValue) {
            this.setState({
                eAError: true,
                reqError: true
            });
        } else if (!this.isEmailValid(eAVal)) {
            this.setState({
                eAValError: true
            });
        } else {
            if (this.emailAddressValue && !this.state.eAValError) {
                this.props.notifySubmitClicked(fnA, lnA, eA, subscribeChecked);
            }
            this.setState({
                firstName: '',
                lastName: '',
                emailAddress: '',
                subscribeChecked: false
            });
        }
    }
}
