import { ITelemetry } from '@msdyn365-commerce/core';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import { IRefineItemToggleNotification } from './refine-item-toggle-notification';

export function getText(element?: HTMLElement | Element): string {
    return !!element ? element.textContent || '' : '';
}

export const enum KeyCodes {
    Back = 8,
    Tab = 9,
    Enter = 13,
    Shift = 16,
    Ctrl = 17,
    Alt = 18,
    Break = 19,
    CapsLock = 20,
    Escape = 27,
    Space = 32,
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    ArrowLeft = 37,
    ArrowUp = 38,
    ArrowRight = 39,
    ArrowDown = 40,
    Print = 44,
    Insert = 45,
    Delete = 46,
    Colon2 = 59, // Opera and Firefox
    Equals2 = 61, // Opera
    Equals3 = 107, // Firefox
    Minus2 = 109, // Opera and Firefox
    Period = 190,
    WindowsLeft = 91,
    WindowsRight = 92,
    WindowsOpera = 219, // Opera
    Menu = 93,
    NumPad0 = 96,
    NumPad1 = 97,
    NumPad2 = 98,
    NumPad3 = 99,
    NumPad4 = 100,
    NumPad5 = 101,
    NumPad6 = 102,
    NumPad7 = 103,
    NumPad8 = 104,
    NumPad9 = 105,
    NumPadMultiply = 106,
    NumPadPlus = 107,
    NumPadMinus = 109,
    NumPadDot = 110,
    NumPadDivide = 111,
    Function1 = 112,
    Function2 = 113,
    Function3 = 114,
    Function4 = 115,
    Function5 = 116,
    Function6 = 117,
    Function7 = 118,
    Function8 = 119,
    Function9 = 120,
    Function10 = 121,
    Function11 = 122,
    Function12 = 123,
    NavigationUp = 138,
    NavigationDown = 139,
    NavigationLeft = 140,
    NavigationRight = 141,
    NavigationAccept = 142,
    NumLock = 144,
    ScrollLock = 145,
    Colon = 186,
    Equals = 187,
    Comma = 188,
    Minus = 189,
    ForwardSlash = 191,
    Tilde = 192,
    GamepadA = 195,
    GamepadB = 196,
    GamepadX = 197,
    GamepadY = 198,
    GamepadRightButton = 199,
    GamepadLeftButton = 200,
    GamepadLeftTrigger = 201,
    GamepadRightTrigger = 202,
    GamepadDPadUp = 203,
    GamepadDPadDown = 204,
    GamepadDPadLeft = 205,
    GamepadDPadRight = 206,
    GamepadSelect = 207,
    GamepadStart = 208,
    GamepadLeftThumbstick = 209,
    GamepadRightThumbstick = 210,
    GamepadLeftThumbstickUp = 211,
    GamepadLeftThumbstickDown = 212,
    GamepadLeftThumbstickRight = 213,
    GamepadLeftThumbstickLeft = 214,
    GamepadRightThumbstickUp = 215,
    GamepadRightThumbstickDown = 216,
    GamepadRightThumbstickRight = 217,
    GamepadRightThumbstickLeft = 218,
    OpenBracket = 219,
    BackSlash = 220,
    CloseBracket = 221,
    Quote = 222
}

/**
 * Types of product refiner values
 */
export const enum ProductRefinerValueDataTypeValue {
    /**
     * Range slider is used for selections like price
     */
    Range = 1,

    /**
     * Range input is a different way to specify ranges and can be expressed with input boxes
     * as well as a set of discrete single-select type values
     */
    RangeInput = 4,

    /**
     * This is a discrete list item, either multi-select or single-select
     */
    List = 5,

    /**
     * Boolean types allows only single-select
     */
    Boolean = 6
}

/**
 * Types of product refiners
 */
export const enum ProductRefinerTypeValue {
    /**
     * Refiner values are single-select
     */
    Single = 0,

    /**
     * Refiner values are multi-select
     */
    Multi = 1
}

/**
 * ProductRefinerSource enum type.
 */
export const enum ProductRefinerSource {
    /**
     * The None member.
     */
    None = 0,
    /**
     * The Attribute member.
     */
    Attribute = 1,
    /**
     * The Category member.
     */
    Category = 2,
    /**
     * The Price member.
     */
    Price = 3,
    /**
     * The Rating member.
     */
    Rating = 4
}

/**
 * Find the refinement criterion associated with this product refiner value
 * @param productRefinerValue product refiner value to match
 * @param refinementCriteria selected refinement criteria
 */
export function findMatchingRefinementCriterion(
    productRefinerValue: ProductRefinerValue,
    refinementCriteria: ProductRefinerValue[]
): ProductRefinerValue | undefined {
    // if the value is a range, then match only on data type value; otherwise match on item string
    return refinementCriteria.find(
        (refinementCriterion: ProductRefinerValue) => isMatchingRefinementCriterion(productRefinerValue, refinementCriterion)
    );
}

/**
 * Find the refinement criterion associated with this product refiner value
 * @param productRefinerValue product refiner value to match
 * @param refinementCriteria selected refinement criteria
 */
export function isMatchingRefinementCriterion(
    productRefinerValue: ProductRefinerValue,
    refinementCriterion: ProductRefinerValue
): boolean {
    // if the value is a range, then match only on data type value; otherwise match on item string
    return (
        refinementCriterion.RefinerRecordId === productRefinerValue.RefinerRecordId &&
        refinementCriterion.RefinerSourceValue === productRefinerValue.RefinerSourceValue &&
        refinementCriterion.DataTypeValue === productRefinerValue.DataTypeValue &&
        (refinementCriterion.DataTypeValue === ProductRefinerValueDataTypeValue.Range ||
            refinementCriterion.LeftValueBoundString === productRefinerValue.LeftValueBoundString)
    );
}

export function getUpdatedRefinementCriteria(
    itemToggleNotification: IRefineItemToggleNotification,
    currentRefinementCriteria: ProductRefinerValue[]): ProductRefinerValue[] {
    const updatedRefinementCriteria: ProductRefinerValue[] = [];
    let toggledItemFound = false;
    currentRefinementCriteria.forEach((selectedCriterion: ProductRefinerValue) => {
        if (isMatchingRefinementCriterion(itemToggleNotification.productRefinerValue, selectedCriterion)) {
            toggledItemFound = true;
            if (itemToggleNotification.isSelecting) {
                const next = {
                    ...selectedCriterion,
                    LeftValueBoundString: itemToggleNotification.rangeStart !== undefined && `${itemToggleNotification.rangeStart}` || selectedCriterion.LeftValueBoundString,
                    RightValueBoundString: itemToggleNotification.rangeEnd !== undefined && `${itemToggleNotification.rangeEnd}` || selectedCriterion.RightValueBoundString,
                };
                updatedRefinementCriteria.push(next);

            } // else the item is being de-selected, so omit it from the refinement criteria
        } else {
            // keep existing criterion because it is not in the item toggle notification
            updatedRefinementCriteria.push(selectedCriterion);
        }
    });

    if (!toggledItemFound) {
        const next = {
            ...itemToggleNotification.productRefinerValue,
            LeftValueBoundString: itemToggleNotification.rangeStart !== undefined && `${itemToggleNotification.rangeStart}` || itemToggleNotification.productRefinerValue.LeftValueBoundString,
            RightValueBoundString: itemToggleNotification.rangeEnd !== undefined && `${itemToggleNotification.rangeEnd}` || itemToggleNotification.productRefinerValue.RightValueBoundString,
        };
        updatedRefinementCriteria.push(next);

        // If single select, then deselect any others in the parent refiner group
        if ((itemToggleNotification.productRefinerValue.DataTypeValue === ProductRefinerValueDataTypeValue.List || itemToggleNotification.productRefinerValue.DataTypeValue === ProductRefinerValueDataTypeValue.Boolean)  &&
            itemToggleNotification.parentProductRefinerHierarchy.RefinerTypeValue === ProductRefinerTypeValue.Single) {
            itemToggleNotification.parentProductRefinerHierarchy.Values.forEach((child: ProductRefinerValue) => {
                if (child.RefinerRecordId === next.RefinerRecordId && child.LeftValueBoundString === next.LeftValueBoundString) {
                    // do nothing
                } else {
                    const matchingIndex = updatedRefinementCriteria.findIndex((criterion: ProductRefinerValue) => isMatchingRefinementCriterion(child, criterion));
                    if (matchingIndex > -1) {
                        updatedRefinementCriteria.splice(matchingIndex, 1);
                    }
                }
            });
        }
    }

    return updatedRefinementCriteria;
}

export function formatPrice(
    amount: string | undefined,
    currency: string | undefined,
    locale: string | undefined,
    telemetry: ITelemetry): string {
    if (!amount || !currency) {
        telemetry.trace(`[refine-menu.utilities.formatPrice] could not format price for ${amount} ${currency}`);
        return amount || '';
    }
    const priceAmount = (amount && Number(amount)) || 0;
    let result: string;

    try {
        result = new Intl.NumberFormat(locale, {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: currency
        }).format(priceAmount);
    } catch (e) {
        result = `${priceAmount} ${currency}`;
        telemetry.warning(`[refine-menu.utilities.formatPrice] Failed to format price for ${result}: ${e}`);
    }

    return result;
}