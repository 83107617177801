// import { observer } from 'mobx-react';
import * as React from 'react';
import { ICittaHeaderData } from '../citta-header.data';
import { ICittaHeaderProps } from '../citta-header.props.autogenerated';

/**
 * The header preferred store component
 */
// @observer
export class HeaderPreferredStore extends React.PureComponent<ICittaHeaderProps<ICittaHeaderData>> {

    // public handleClick = () => { return this._findPreferreStoreOnClick(this.props); };

    public render(): JSX.Element | undefined {
        // const {
        //     data: {
        //         storeSelectorStateManager: { result: storeSelectorStateManager }
        //     },
        //     slots,
        //     resources
        // } = this.props;
        // if (slots && slots.storeSelector && slots.storeSelector.length) {
        //     const storeName = storeSelectorStateManager!.preferredStore && storeSelectorStateManager!.preferredStore.StoreName ?
        //         storeSelectorStateManager!.preferredStore.StoreName :
        //         resources.headerPreferredStoreText;

            // return (
            //     <div className='ms-header__preferred-store-container'>
            //         <button className='ms-header__preferred-store-btn msc-btn' color='secondary' aria-label={resources.headerPreferredStoreAriaLabel} onClick={this.handleClick} >
            //             <span className='ms-header__preferred-store-text'>{storeName}</span>
            //         </button>
            //         {slots.storeSelector}
            //     </div>
            // );
        // }

        return undefined;
    }

    // private async _findPreferreStoreOnClick(props: ICittaHeaderProps<ICittaHeaderData>): Promise<void> {
    //     const { data: { storeSelectorStateManager: { result: storeSelectorStateManager } }, modules } = props;
    //     if (!storeSelectorStateManager) {
    //         return;
    //     }
    //     let storeSelectorId: string = '';
    //     if (modules && Object.keys(modules).length > 0 && modules.storeSelector && modules.storeSelector.length > 0) {
    //         storeSelectorId = modules.storeSelector[0].id;
    //     }
    //     storeSelectorStateManager.openDialog({
    //         id: storeSelectorId,
    //         showMapViewLink: false,
    //         onLocationSelected: orgUnitLocation => {
    //             return Promise.resolve();
    //         }
    //     }).catch((error: Error) => {
    //         if (props.telemetry) {
    //             props.telemetry.error(error.message);
    //             props.telemetry.debug('Unable to set preferred store');
    //         }
    //         return;
    //     });
    // }
}

export default HeaderPreferredStore;
