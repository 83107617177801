import * as React from 'react';
import Dropdown, { IDropdownState } from './Dropdown';
import { IDropdownProps } from './Dropdown.props';
import { DropdownContextProvider, IDropdownContextType } from './DropdownProvider';

// tslint:disable-next-line:no-any no-empty
export const DropdownContext = React.createContext({ isOpen: false, toggle: () => {}, inNavbar: false });

/**
 * Uncontrolled Dropdown  component
 */
export default class UncontrolledDropdown extends React.Component<IDropdownProps,IDropdownState> {

    public render(): JSX.Element {
        return (
            <DropdownContextProvider isOpen={this.props.isOpen}>
                <DropdownContext.Consumer>
                    {(context: IDropdownContextType) => {
                        return(
                            <Dropdown isOpen={context.isOpen} toggle={context.toggle}>
                                {this.props.children}
                            </Dropdown>
                        );}}
                </DropdownContext.Consumer>
            </DropdownContextProvider>
        );
    }
}
