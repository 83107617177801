import classNames from 'classnames';
import * as React from 'react';
import { mapToCssModules } from '../../shared-utils/utilities';

export interface IDropdownItemProps {
    children?: Node | string;
    active?: boolean;
    disabled?: boolean;
    divider?: boolean;
    // @ts-ignore
    tag?: React.ReactType;
    header?: boolean;
    onClick?: Function;
    className?: string;
    cssModule?: object;
    toggle?: Function;
    href?:string;
    'aria-setsize'?: number;
    'aria-posinset'?: number;
    id?: string;
}

/**
 * Dropdown Item component
 */
export default class DropdownItem extends React.Component<IDropdownItemProps> {
    public static defaultProps: Partial<IDropdownItemProps> = {
        tag: 'button'
    };
    public props: IDropdownItemProps;

    constructor(props: IDropdownItemProps) {
        super(props);
        this.props = props;
        this.onClick = this.onClick.bind(this);
        this.getTabIndex = this.getTabIndex.bind(this);
    }

    public onClick(e: Event): void {
        if (this.props.disabled || this.props.header || this.props.divider) {
            e.preventDefault();

            return;
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }

        if (this.props.toggle) {
            this.props.toggle(e);
        }
    }

    public getTabIndex(): String {
        if (this.props.disabled || this.props.header || this.props.divider) {
            return '-1';
        }

        return '0';
    }

    public render(): JSX.Element {
        const tabIndex = this.getTabIndex();
        const {
            className,
            cssModule,
            divider,
            tag,
            header,
            active,
            toggle,
            ...props } = this.props;

        const toggleValue = toggle || false;
        const classes = mapToCssModules(
            classNames(
                className,
                {
                    disabled: this.props.disabled,
                    'dropdown-item': !divider && !header,
                    active: active,
                    'dropdown-header': header,
                    'dropdown-divider': divider
                }
            ),
            cssModule
        );

        let Tag = tag || 'button';
        if (Tag === 'button') {
            if (header) {
                Tag = 'h6';
            } else if (divider) {
                Tag = 'div';
            } else if (props.href) {
                Tag = 'a';
            }
        }

        return (
            <Tag
                type={(Tag === 'button' && (props.onClick || toggleValue)) ? 'button' : undefined}
                {...props}
                role='menuitem'
                tabIndex={tabIndex}
                className={classes}
                onClick={this.onClick}
                id={this.props.id}
            />
        );
    }
}
