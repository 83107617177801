import * as React from 'react';
import { IDropdownProps } from '../../citta-dropdown/Dropdown.props';
import { getText, KeyCodes } from '../utilities';
import { IRichSelectProps, IRichSelectSelectedItem } from './RichSelect.props';

export interface ISelectState {
    dropdownOpen: boolean;
    dropdownText: string;
    selectedIndices: number[];
}

/**
 * RichSelect - This component is a dropdown specifically created to mimic a select menu.
 * Can generate a native select for forms
 */
export default class RichSelect extends React.PureComponent<IRichSelectProps, ISelectState> {
    private ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private options: HTMLOptionElement[] = [];

    constructor(props: Readonly<IRichSelectProps>) {
        super(props);
        this._toggle = this._toggle.bind(this);
        this._click = this._click.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        // @ts-ignore
        const dropdown = React.Children.toArray(this.props.children)[0] as React.ReactElement<IDropdownProps>;
        const toggle = dropdown.props.children![0];
        this.state = { dropdownOpen: !!dropdown.props.isOpen, dropdownText: toggle.props.children, selectedIndices: [] };
    }

    public componentDidMount(): void {
        if (this.props.form) {
            const select = document.createElement('select');
            select.setAttribute('aria-hidden', 'true');
            select.setAttribute('tabindex', '-1');
            select.setAttribute('name', this.props.name || this.props.id);
            select.className = 'sr-only';

            if (this.props.multiSelect) {
                select.setAttribute('multiple', 'true');
            }

            const items = this.ref.current!.getElementsByClassName('rich-select__item');

            for (let i = 0; i < items.length; i++) {
                const text = getText(items.item(i)!);
                const option = document.createElement('option');
                option.appendChild(document.createTextNode(text));
                this.options.push(option);
                select.appendChild(option);
            }

            this.ref.current!.children[0].appendChild(select);
        }
    }

    public render(): JSX.Element {
        // @ts-ignore
        let dropdown = React.Children.toArray(this.props.children)[0] as React.ReactElement<IDropdownProps>;
        let toggle = dropdown.props.children![0];
        let menu = dropdown.props.children![1];
        let menuItems = React.Children.toArray(menu.props.children);
        // @ts-ignore
        menuItems = menuItems.map((item: React.ReactChild, index: number) => {
            item = item as React.ReactElement;
            return React.cloneElement(item, {
                'aria-setsize': menuItems.length,
                'aria-posinset': index + 1,
                'data-index': index,
                id: item.props.id || `${this.props.id}-${index}`,
                active: this.state.selectedIndices.indexOf(index) > -1,
                onClick: this._click,
                onKeyDown: this._handleKeyDown,
                className: 'rich-select__item'
            });
        });

        toggle = React.cloneElement(toggle, {
            toggle: this._toggle,
            id: (this.props.id || ''),
            'aria-expanded': this.state.dropdownOpen,
            isOpen: this.state.dropdownOpen,
            children: this.state.dropdownText,
            className: 'rich-select__toggle',
            onKeyDown: this._handleKeyDown,
            key: 'rich-select__toggle'
        });

        menu = React.cloneElement(menu, { isOpen: this.state.dropdownOpen, children: menuItems, className: 'rich-select__menu', key: 'rich-select__menu' });

        dropdown = React.cloneElement(dropdown, {
            toggle: this._toggle,
            className: 'rich-select',
            isOpen: this.state.dropdownOpen,
            children: [toggle, menu]
        });

        return <div ref={this.ref} className={this.props.className}>{dropdown}</div>;
    }

    private _toggle(): void {
        this.setState((prevState: ISelectState) => {
            return { dropdownOpen: !prevState.dropdownOpen };
        });
    }

    // tslint:disable-next-line:no-any
    private _handleKeyDown(event: any): void {
        // To handle focus on keydown event of toggle button
        if (event.target.className.includes('rich-select__toggle')) {
            event.stopPropagation();
            event.target.focus();
        }
        // To handle focus on keydown event of menu item
        if (event.target.className.includes('dropdown-item') && event.which === KeyCodes.Enter) {
            event.stopPropagation();
            this._click(event);
            this._toggle();
        }
        // To handle focus on toggle button on Escape key
        if (event.which === KeyCodes.Escape) {
            event.stopPropagation();
            this._toggle();
            const toggle = this.ref.current!.getElementsByClassName('rich-select__toggle').item(0) as HTMLElement;
            toggle.focus();
        }
    }

    private _click(event: Event): void {
        const target = event.target as HTMLElement;
        const index = parseInt(target.getAttribute('data-index')!, 10);
        const selectedIndices = this._toggleSelectIndex(target, index);
        const dropdownText = this.props.multiSelect ? this.state.dropdownText : getText(target);
        this._updateNativeSelect(index);

        this.setState((prevState: ISelectState) => {
            return { dropdownOpen: !prevState.dropdownOpen, dropdownText: dropdownText, selectedIndices: selectedIndices };
        });

        const toggle = this.ref.current!.getElementsByClassName('rich-select__toggle').item(0) as HTMLElement;
        toggle.focus();

        if (this.props.onChange) {
            const items = this.ref.current!.getElementsByClassName('rich-select__item');
            const selectedItems: IRichSelectSelectedItem[] = [];
            selectedIndices.forEach((selectedIndex: number) => {
                const element = items.item(selectedIndex)!;
                selectedItems.push({ id: element.getAttribute('id')!, value: getText(element), index: element.getAttribute('data-index')!});
            });

            this.props.onChange({ selectId: this.props.id, selectedItems: selectedItems });
        }
    }

    private _toggleSelectIndex(target: HTMLElement, index: number): number[] {
        if (this.props.multiSelect) {
            const selected = target.classList.contains('active');
            const selectedIndex = this.state.selectedIndices.indexOf(index);
            const indices = this.state.selectedIndices;

            if (selected) {
                indices.splice(selectedIndex, 1);
            } else {
                indices.push(index);
            }

            return indices;
        }

        return [index];
    }

    private _updateNativeSelect(index: number): void {
        if (this.props.form) {
            if (this.props.multiSelect) {
                if (this.options[index].getAttribute('selected')) {
                    this.options[index].removeAttribute('selected');
                } else {
                    this.options[index].setAttribute('selected', 'true');
                }
            } else {
                if (this.state.selectedIndices.length) {
                    const currentIndex = this.state.selectedIndices[0];
                    this.options[currentIndex].removeAttribute('selected');
                }

                this.options[index].setAttribute('selected', 'true');
            }
        }
    }
}