import classNames from 'classnames';
import { Placement } from 'popper.js';
import * as React from 'react';
import { Popper } from 'react-popper';
import { mapToCssModules } from '../../shared-utils/utilities';

const noFlipModifier = { flip: { enabled: false } };

export interface IDropdownMenuProps extends React.DOMAttributes<HTMLElement> {
    // @ts-ignore
    component?: React.ReactType;
    right?: boolean;
    flip?: boolean;
    // tslint:disable-next-line:no-any
    popperModifiers?: any;
    className?: string;
    cssModule?: object;
    persist?: boolean;
    isOpen?: boolean;
    inNavbar?: boolean;
    placement?:Placement;
    // tslint:disable-next-line:no-any
    modifiers?: any;
}

/**
 * DropdownMenu component
 */
export default class DropdownMenu extends React.Component<IDropdownMenuProps> {
    public static defaultProps: Partial<IDropdownMenuProps> = {
        component: 'div',
        flip: true,
        inNavbar: false,
        isOpen: false
    };

    public props: IDropdownMenuProps;

    constructor(props: IDropdownMenuProps) {
        super(props);
        this.props = props;
    }

    public render(): JSX.Element {
        const {
            className,
            component,
            cssModule,
            right,
            flip,
            popperModifiers,
            persist,
            placement,
            isOpen,
            inNavbar,
            ...attrs
        } = this.props;
        const classes = mapToCssModules(
            classNames(className, 'dropdown-menu', {
                'dropdown-menu-right': right,
                show: this.props.isOpen
            }),
            cssModule
        );

        if (persist || (this.props.isOpen && !this.props.inNavbar)) {
            attrs.modifiers = !flip
                ? {
                      ...popperModifiers,
                      ...noFlipModifier
                  }
                : popperModifiers;

            return (
                <Popper
                    tabIndex='-1'
                    role='menu'
                    {...attrs}
                    aria-hidden={!this.props.isOpen}
                    className={classes}
                    placement={placement}
                    x-placement={placement}
                />
            );
        }
        // @ts-ignore
        const Tag: React.ReactType = this.props.component || 'div';

        return <Tag tabIndex='-1' role='menu' {...attrs} aria-hidden={!this.props.isOpen} className={classes} x-placement={placement} />;
    }
}
