import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Manager } from 'react-popper';
import { keyCodes, mapToCssModules } from '../../shared-utils/utilities';
import { IDropdownProps } from './Dropdown.props';

export type DropdownDirection = 'up' | 'down' | 'left' | 'right';
export type DropdownAddonType = 'prepend' | 'append';

export interface IDropdownState {
    dropdownOpen: boolean;
    example2?: boolean;
    ddDropup?: boolean;
    ddDropleft?: boolean;
    ddDropright?: boolean;
    ddModifiers?: boolean;
}

/**
 * Dropdown component
 */
export default class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
    public static defaultProps: Partial<IDropdownProps> = {
        isOpen: false,
        direction: 'down',
        nav: false,
        active: false,
        addonType: false,
        inNavbar: false,
        setActiveFromChild: false,
    };

    public props: IDropdownProps;

    constructor(props: IDropdownProps) {
        super(props);
        this.props = props;
        this.addEvents = this.addEvents.bind(this);
        this.handleFirstItemFocus=this.handleFirstItemFocus.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.removeEvents = this.removeEvents.bind(this);
        this.toggle = this.props.toggle ? this.props.toggle.bind(this) : this.toggle.bind(this);
    }

    public componentDidMount(): void {
        this.handleProps();
    }

    public componentDidUpdate(prevProps: IDropdownProps): void {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.handleProps();
        }
    }

    public componentWillUnmount(): void {
        this.removeEvents();
    }

    public getContainer(): Element {
        return ReactDOM.findDOMNode(this) as Element;
    }
    public handleFirstItemFocus(): void {
        const container = this.getContainer();
        const items = container.querySelectorAll(`.dropdown-item:not(.disabled)`);
        if (!items.length) { return; }
        const element = items[0] as HTMLElement;
        if (element) {
            element.focus();
        }
    }
    public addEvents = () => {
        ['click', 'touchstart', 'keyup'].forEach(
            (event: string) => document.addEventListener(event, this.handleDocumentClick, true)
        );
    }

    public removeEvents(): void {
        ['click', 'touchstart', 'keyup'].forEach(
            (event: string) => document.removeEventListener(event, this.handleDocumentClick, true)
        );
    }

    // tslint:disable-next-line:no-any
    public handleDocumentClick (e: any): void {
        if (e && (e.which === 3 || (e.type === 'keyup' && e.which !== keyCodes.tab))) { return; }
        const container = this.getContainer();

        if (container.contains(e.target) && container !== e.target && (e.type !== 'keyup' || e.which === keyCodes.tab)) {
            return;
        }

        this.toggle(e);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public handleKeyDown(
        // tslint:disable-next-line:no-any
        e: any
    ): void {

        const tagName = e.target.tagName;
        if ([keyCodes.esc, keyCodes.up, keyCodes.down, keyCodes.space, keyCodes.enter].indexOf(e.which) === -1 ||
            (tagName === 'BUTTON' && (e.which === keyCodes.space || e.which === keyCodes.enter)) ||
            (tagName === 'INPUT' || tagName === 'TEXTAREA')) {
            return;
        }

        e.preventDefault();
        if (this.props.disabled) { return; }

        const container = this.getContainer();

        if ((e.which === keyCodes.space || e.which === keyCodes.enter) && this.props.isOpen && container !== e.target) {
            e.target.click();
        }

        if (e.which === keyCodes.esc || !this.props.isOpen) {
            this.toggle(e);
            const expandedElement = container.querySelector('.dropdown-toggle') as HTMLElement;
            if (expandedElement) {
                expandedElement.focus();
            }
            return;
        }

        const menuClass = mapToCssModules('dropdown-menu', this.props.cssModule);
        const itemClass = mapToCssModules('dropdown-item', this.props.cssModule);
        const disabledClass = mapToCssModules('disabled', this.props.cssModule);

        const items = container.querySelectorAll(`.${menuClass} .${itemClass}:not(.${disabledClass})`);

        if (!items.length) { return; }

        let index = -1;
        for (let i = 0; i < items.length; i += 1) {
            if (items[i] === e.target) {
                index = i;
                break;
            }
        }

        if (e.which === keyCodes.up && index > 0) {
            index -= 1;
        }

        if (e.which === keyCodes.down && index < items.length - 1) {
            index += 1;
        }

        if (index < 0) {
            index = 0;
        }

        const element = items[index] as HTMLElement;

        if (element) {
            element.focus();
        }
    }

    public handleProps(): void {
        if (this.props.isOpen) {
            this.addEvents();
            this.handleFirstItemFocus();
        } else {
            this.removeEvents();
        }
    }

    public toggle(e: Event):void {

        if(!this.props.disabled) {
            this.props.toggle && this.props.toggle(e);
        } else {
            e.preventDefault();
        }
    }

    public render(): JSX.Element {
        const {
            className,
            cssModule,
            dropup,
            isOpen,
            group,
            size,
            nav,
            setActiveFromChild,
            active,
            addonType,
            toggle,
            disabled,
            direction,
            inNavbar,
            ...attrs
        } = this.props;

        const directionOrDefault = (direction === 'down' && dropup) ? 'up' : direction;

        attrs.tag = attrs.tag || (nav ? 'li' : 'div');

        let subItemIsActive = false;
        if (setActiveFromChild) {
            React.Children.map(
                this.props.children![1].props.children,
                // tslint:disable-next-line:no-any
                (dropdownItem: any) => {
                    // tslint:disable-next-line:no-any
                    const derefdropdownItem: any = dropdownItem;
                    // tslint:disable-next-line:no-any
                    const dropdownItemProps: any = derefdropdownItem.props;
                    if (dropdownItemProps.active) {
                        subItemIsActive = true;
                    }
                }
            );
        }

        const classes = mapToCssModules(
            classNames(
                className,
                directionOrDefault !== 'down' && `drop${directionOrDefault}`,
                nav && active ? 'active' : false,
                setActiveFromChild && subItemIsActive ? 'active' : false,
                addonType ? `input-group-${addonType}` : false,
                group ? 'btn-group' : false,
                !!size ? `btn-group-${size}` : false,
                !group && !addonType ? 'dropdown' : false,
                isOpen ? 'show' : false,
                nav ? 'nav-item' : false
            ),
            cssModule
        );

        return <Manager {...attrs} className={classes} onKeyDown={this.handleKeyDown} />;
    }
}
