import * as React from 'react';
export interface IAfterPayInfoProps {
    title: React.ReactNode;
    price:number;
    afterpayImage:string;
    afterpaydesktopImg:string;
    afterpaymobileImg:string;
}

// tslint:disable-next-line:no-empty-interface
interface IAfterPayInfoState {
    afterpayAvailable: boolean;
    afterpayInstalment: number | string;
    showAfterpayModal: boolean;
    afterpayMin: number;
    afterpayMax:number;
}
/**
 * Configuration entity interface.
 */
export interface IConfiguration {
    type?: string;
    description?: string;
    minimumAmount?: IMinimumAmount;
    maximumAmount?: IMaximumAmount;
}
export interface IMinimumAmount {
    amount?: string;
    currency?: string;
}
export interface IMaximumAmount {
    amount?: string;
    currency?: string;
}
// tslint:disable-next-line:class-name
export interface IDXC_GetConfigurationResponse {
    configuration?: IConfiguration[];
    success?: boolean;
    errorText?: string;
}

/**
 *
 * The PromoCode component renders the promocode section.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class AfterPayInfo extends React.Component<IAfterPayInfoProps, IAfterPayInfoState> {
    constructor(props: IAfterPayInfoProps, state: IAfterPayInfoState) {
        super(props);
        this.state = {
            afterpayAvailable: false,
            afterpayInstalment: 0,
            showAfterpayModal: false,
            afterpayMin: 0,
            afterpayMax: 0,
        };
    }

    public async componentDidMount(): Promise<void> {

        // Afterpay get configuration
        // const afterpayConfig: IDXC_GetConfigurationResponse = await this._afterpayGetConfiguration();
        // TODO replace the below Mock  with actual data action response
        const afterpayConfig: IDXC_GetConfigurationResponse = {
            configuration:[
              {
                type:'PAY_BY_INSTALLMENT',description:'Pay over time',minimumAmount:{
                  amount:'',currency:''
                },maximumAmount:{
                  amount:'2000.00',currency:'NZD'
                }
              }
            ],success:true,errorText:''
          };
        if (afterpayConfig && afterpayConfig.success && afterpayConfig.configuration) {
            const configResult = afterpayConfig.configuration[0];
            this._processAfterpayConfig(configResult);
            this._calculateAfterpayInstalment();
        }
    }
    public componentDidUpdate(): void {
        this._calculateAfterpayInstalment();
    }
    public render(): JSX.Element {
        const wrapperClassName = `afterpay-info ${this.state.afterpayAvailable ? 'afterpay-loaded' : ''}`;
        return (
            <div>
                <div className={wrapperClassName}>
                    <div className="afterpay-info_content">
                        <hr/>
                        <section className="pay-later-section">
                            <div className="upper-details"> 
                                <img
                                    src={this.props.afterpayImage}
                                    alt='Afterpay'
                                />
                                <div className="breakdown"> 
                                    <div>{this.props.title}</div>
                                    <div className="price">${this.props.price}</div>
                                    <div>pay in 4 fortnightly instalments ${(this.props.price / 4).toFixed(2)}</div>
                                </div>
                            </div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">EASY SIGN-UP</div>
                            <div className="pay-later-section_text">Easy Sign-up: Just select "AfterPay" as your form of payment at checkout. All you need is a debit or credit card to create an account.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">PAY IN 4 INSTALMENTS</div>
                            <div className="pay-later-section_text">You'll pay your first instalment at the time of purchase, and the remainder every fortnight.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">INSTANT APPROVAL</div>
                            <div className="pay-later-section_text">You’ll know you’ve been approved within seconds. Orders ship as they would with any other form of payment, as quickly as you choose.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">NOTHING EXTRA TO PAY</div>
                            <div className="pay-later-section_text">With interest-free instalments, the only fees are for late payments. You can find your payment schedule in the Afterpay app and we'll send you regular reminders.</div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    private _processAfterpayConfig(configResult: IConfiguration): void {
        const min = configResult.minimumAmount && configResult.minimumAmount.amount && configResult.minimumAmount.amount !== '' ?
            +configResult.minimumAmount.amount : 0;
        const max = configResult.maximumAmount && configResult.maximumAmount.amount && configResult.maximumAmount.amount !== '' ?
            +configResult.maximumAmount.amount : 0;
        this.setState({
            afterpayMin: min,
            afterpayMax: max
        });
    }
    private _calculateAfterpayInstalment(): void {
        // compare product price to min and max afterpay prices
        const price = this.props.price ? this.props.price : null;
        const isAfterpayAvailable = price && price > this.state.afterpayMin && price <= this.state.afterpayMax || false;
        const instalment = price && isAfterpayAvailable ? (price / 4).toFixed(2) : 0;

        if (isAfterpayAvailable !== this.state.afterpayAvailable || instalment !== this.state.afterpayInstalment) {
            this.setState({
                afterpayAvailable: isAfterpayAvailable,
                afterpayInstalment: price && isAfterpayAvailable ? (price / 4).toFixed(2) : 0
            });
        }
    }

}

export default AfterPayInfo;