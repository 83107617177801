import * as React from 'react';
export interface ILaybuyPriceBreakdownProps {
    title: React.ReactNode;
    price: number;
    imageUrl: string;
}

// tslint:disable-next-line:no-empty-interface
interface ILaybuyPriceBreakdownInfoState {
    laybuyPriceBreakdownInstalment: number | string;
    showLaybuyPriceBreakdownModal: boolean;
}

/**
 *
 * The PromoCode component renders the promocode section.
 * @extends {React.PureComponent<IRefineSubmenuProps>}
 */
class LaybuyPriceBreakdown extends React.Component<ILaybuyPriceBreakdownProps, ILaybuyPriceBreakdownInfoState> {
    constructor(props: ILaybuyPriceBreakdownProps, state: ILaybuyPriceBreakdownInfoState) {
        super(props);
        this.state = {
            laybuyPriceBreakdownInstalment: 0,
            showLaybuyPriceBreakdownModal: false,
        };
        this._toggleModal = this._toggleModal.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.price) {
            this._calculatelaybuyPriceBreakdownInstalment();
        }
    }
    public componentDidUpdate(): void {
        this._calculatelaybuyPriceBreakdownInstalment();
    }
    public render(): JSX.Element {
        // const TERMS_URL = 'https://www.laybuy.com/au/consumer-terms';
        const AMOUNT_OF_INSTALLMENTS = 6;
        // const ICON_PATH = this.props.imageUrl;
        const hasPrice: boolean = this.state.laybuyPriceBreakdownInstalment !== 0 ? true : false;
        const wrapperClassName = `laybuyPriceBreakdown-info ${hasPrice ? 'laybuyPriceBreakdown-loaded' : ''}`;
        return (
            <div>
                <div className={wrapperClassName}>
                    <div className="afterpay-info_content">
                        <hr/>
                        <section className="pay-later-section">
                            <div className="upper-details"> 
                                <img
                                    src={this.props.imageUrl}
                                    alt='Afterpay'
                                />
                                <div className="breakdown"> 
                                    <div>{this.props.title}</div>
                                    <div className="price">${this.props.price}</div>
                                    <div>pay in 6 weekly payments from ${(this.props.price / 6).toFixed(2)}</div>
                                </div>
                            </div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">EASY SIGN-UP</div>
                            <div className="pay-later-section_text">Easy Sign-up: Just select "Laybuy" as your form of payment at checkout. All you need is a debit or credit card to create an account.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">PAY IN {AMOUNT_OF_INSTALLMENTS} INSTALMENTS</div>
                            <div className="pay-later-section_text">Choose your payment day, view your schedule and select pay now to complete your order.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">INSTANT APPROVAL</div>
                            <div className="pay-later-section_text">Once approved you will be able to complete purchases using Laybuy.</div>
                        </section>
                        <hr />
                        <section className="pay-later-section">
                            <div className="pay-later-section_title">NOTHING EXTRA TO PAY</div>
                            <div className="pay-later-section_text">Laybuy doesn't charge you any interest or upfront fees.</div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    private _toggleModal(): void {
        this.setState({
            showLaybuyPriceBreakdownModal: !this.state.showLaybuyPriceBreakdownModal
        });
    }
    private _calculatelaybuyPriceBreakdownInstalment(): void {
        // compare product price to min and max laybuyPriceBreakdown prices
        const price = this.props.price ? this.props.price : null;
        const instalment = price ? (price / 6).toFixed(2) : 0;
        if (instalment !== this.state.laybuyPriceBreakdownInstalment) {
            this.setState({
                laybuyPriceBreakdownInstalment: price ? (price / 6).toFixed(2) : 0
            });
        }
    }

}

export default LaybuyPriceBreakdown;