import * as React from 'react';
import { IDropdownState } from './Dropdown';
import { IDropdownProps } from './Dropdown.props';
import { DropdownContext } from './UncontrolledDropdown';

export interface IDropdownContextType {
    isOpen: boolean;
    inNavbar: boolean;
    // tslint:disable-next-line:prefer-method-signature
    toggle: () => void;
}

/**
 * Dropdown Provider
 */
export class DropdownContextProvider extends React.Component<IDropdownProps, IDropdownState> {

    constructor(props: IDropdownProps, state: IDropdownState) {
        super(props, state);
        this.state = {
            dropdownOpen: this.props.isOpen || false,
        };
        this.togglefn = this.togglefn.bind(this);
    }

    public render(): JSX.Element {

        return (
            <DropdownContext.Provider
                value = {{
                    isOpen: this.state.dropdownOpen,
                    toggle: this.togglefn,
                    inNavbar: false
                }}
            >
                {this.props.children}
            </DropdownContext.Provider>
        );
    }

    private togglefn = (): void =>  {
        this.setState((prevState: IDropdownState) => {
            return {
                dropdownOpen: !prevState.dropdownOpen
            };
        });
    }
}
