import { getConfigureErrors, getGenericError, getQuantityError, IBuyboxCallbacks, IBuyboxData, IBuyboxProps, IBuyboxResources, IBuyboxState, IBuyboxViewProps } from '@msdyn365-commerce-modules/buybox';
import { Alert, Button, format, getTelemetryObject, Heading, INodeProps, ITelemetryContent, Quantity } from '@msdyn365-commerce-modules/utilities';
import {
    AddToWishlistComponent,
    IAddToCartFailureResult,
    IWishlistActionErrorResult,
    PriceComponent,
    RatingComponent
} from '@msdyn365-commerce/components';
import { getUrlSync, IImageSettings } from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import classnames from 'classnames';
import * as React from 'react';
import { IBuyboxProps as IBuyboxExtentionProps } from '../../definition-extensions/buybox.ext.props.autogenerated';
import { IBuyBoxData } from '../buybox.data';
import AddToCartComponent from './add-to-cart.component';


export function getBuyBoxInventoryLabel(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        data: { productAvailableQuantity: { result: productAvailableQuantity} },
    } = props;
    if(!productAvailableQuantity || !productAvailableQuantity.length) {
        return undefined;
    }
    if(productAvailableQuantity &&
        productAvailableQuantity.length&&
        productAvailableQuantity[0].StockLevelLabel) {
        const inventoryClassName = productAvailableQuantity[0].StockLevelCode?
            `ms-buybox__inventory-label ms-buybox__inventory-code-${productAvailableQuantity[0].StockLevelCode.toLowerCase()}`:
            'ms-buybox__inventory-label';
        return (<div className='ms-buybox__inventory-info'><span className={inventoryClassName} >{productAvailableQuantity[0].StockLevelLabel}</span></div>);
    }

    return undefined;
}
export function getBuyboxProductTitle(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product } },
        config: { titleHeadingTag = 'h1' }
    } = props;

    return product && (
        <Heading
            className='ms-buybox__product-title'
            headingTag={titleHeadingTag}
            text={product.Name || ''}
        />
    );
}

export function getBuyboxProductDescription(props: IBuyboxProps<IBuyboxData> & IBuyboxExtentionProps<IBuyBoxData>): React.ReactElement | undefined {
    const {
        data: { product: { result: product }, productAttributes: { result: productAttributes } }, config
    } = props;
    const isB2B = props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits === 'b2b';
    const description = product && product.Description ? product.Description.replace(/<[^>]+>/g, '') : '';
    const descriptionP2Name = config.webDescriptionP2Name && config.webDescriptionP2Name.toUpperCase() || 'WEB DESCRIPTION P2';
    const descriptionP3Name = config.webDescriptionP3Name && config.webDescriptionP3Name.toUpperCase() || 'WEB DESCRIPTION P3';
    const descriptionP4B2BName = config.webDescriptionP4B2BName && config.webDescriptionP4B2BName.toUpperCase() || 'WEB DESCRIPTION P4 B2B';
    const descriptionP2 = descriptionP2Name && getAttributeText(descriptionP2Name, productAttributes);
    const descriptionP3 = descriptionP3Name && getAttributeText(descriptionP3Name, productAttributes);
    const descriptionP4B2B = descriptionP4B2BName && getAttributeText(descriptionP4B2BName, productAttributes);
    return product && (
        <div className={classnames('ms-buybox__product-description', config.className)}>
            <p>{description}</p>
            {descriptionP2 &&
                <p>{descriptionP2}</p>
            }
            {descriptionP3 &&
                <p>{descriptionP3}</p>
            }
            {isB2B && descriptionP4B2B &&
                <p>{descriptionP4B2B}</p>
            }
        </div>
    );
}

function getAttributeText(attributeName: string, productAttributes: AttributeValue[] | undefined): string | undefined {
    if (productAttributes && productAttributes.length) {
        const filterAttributes = productAttributes.filter(
            attribute => attribute.Name && attribute.Name.toUpperCase() === attributeName
        );
        return filterAttributes &&
            filterAttributes.length > 0 &&
            filterAttributes[0].TextValue ? filterAttributes[0].TextValue : undefined;
    }
    return;
}

export interface IBuyboxAddToCartViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
// tslint:disable-next-line:max-func-body-length
export function getBuyboxAddToCart(props: IBuyboxViewProps & IBuyboxExtentionProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        data: { cart: { result: cart }, product: { result: product }, productPrice: { result: productPrice }, productAvailableQuantity: { result: productAvailableQuantity}, productDimensions: { result: productDimensions } },
        resources
    } = props;

    const
        {
            quantity,
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct,
            isUpdatingDimension
        } = state;

    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        let quantityErrorForState: string | undefined;
        let otherErrorForState: string | undefined = getGenericError(result, cart, resources, context, product, productAvailability, undefined);
        if (result.failureReason === 'OUTOFSTOCK') {
            quantityErrorForState = result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined;
        } else if (result.failureReason === 'CARTACTIONFAILED'
            && result.cartActionResult
            && (result.cartActionResult.substatus === 'MAXQUANTITY' || result.cartActionResult.substatus === 'QUANTITYLIMITS')) {
            quantityErrorForState = getGenericError(result, cart, resources, context, product, productAvailability, undefined);
            otherErrorForState = undefined; // prevent error duplication in otherError and quantityError
        }

        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: quantityErrorForState,
            configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
            otherError: otherErrorForState
        });
    };

    const dialogStrings = {
        goToCartText: resources.buyBoxGoToCartText,
        continueShoppingText: resources.buyBoxContinueShoppingText,
        headerItemOneText: resources.buyBoxSingleItemText,
        headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
        headerMessageText: resources.buyBoxHeaderMessageText,
        freePriceText: resources.priceFree,
        originalPriceText: resources.originalPriceText,
        currentPriceText: resources.currentPriceText
    };

    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };
    const productAvailability = state.productAvailableQuantity ?
        state.productAvailableQuantity.ProductAvailableQuantity :
        productAvailableQuantity && productAvailableQuantity.length?
            productAvailableQuantity[0].ProductAvailableQuantity: undefined;

    const isLoading = props.data.productAvailableQuantity.status === 'LOADING';
    if (isLoading) {
        callbacks.changeUpdatingDimension(false);
    }
    const telemetryContent: ITelemetryContent = getTelemetryObject(props.context.request.telemetryPageName!,
                                                                   props.friendlyName,
                                                                   props.telemetry);
    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-cart-container'
        },
        button: product && (
            <AddToCartComponent
                addToCartText={resources.addToCartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{product: product, price: productPrice, productDimensions: productDimensions, productAvailableQuantity: productAvailableQuantity}}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                getSelectedProduct={selectedProduct}
                productAvailability={productAvailability}
                isLoading={isLoading}
                isUpdatingDimension={isUpdatingDimension}
                changeUpdatingDimension={callbacks.changeUpdatingDimension}
                dialogStrings={dialogStrings}
                gridSettings={props.context.request.gridSettings}
                imageSettings={defaultImageSettings}
                telemetryContent= {telemetryContent}
                props = {props}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'ADDTOCART'}
            />
        )
    };
}

export function getBuyboxplacebackorderAddToCart(props: IBuyboxViewProps & IBuyboxExtentionProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToCartViewProps {
    const {
        id,
        typeName,
        context,
        data: { cart: { result: cart }, product: { result: product }, productPrice: { result: productPrice }, productAvailableQuantity: { result: productAvailableQuantity}, productDimensions: { result: productDimensions } },
        resources
    } = props;

    const
        {
            quantity,
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct,
            isUpdatingDimension
        } = state;

    const onAddToCartFailed = (result: IAddToCartFailureResult) => {
        let quantityErrorForState: string | undefined;
        let otherErrorForState: string | undefined = getGenericError(result, cart, resources, context, product, productAvailability, undefined);
        if (result.failureReason === 'OUTOFSTOCK') {
            quantityErrorForState = result.failureReason === 'OUTOFSTOCK' ? getQuantityError(result.stockLeft, resources) : undefined;
        } else if (result.failureReason === 'CARTACTIONFAILED'
            && result.cartActionResult
            && (result.cartActionResult.substatus === 'MAXQUANTITY' || result.cartActionResult.substatus === 'QUANTITYLIMITS')) {
            quantityErrorForState = getGenericError(result, cart, resources, context, product, productAvailability, undefined);
            otherErrorForState = undefined; // prevent error duplication in otherError and quantityError
        }

        callbacks.updateErrorState({
            errorHost: 'ADDTOCART',
            quantityError: quantityErrorForState,
            configureErrors: result.failureReason === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
            otherError: otherErrorForState
        });
    };

    const dialogStrings = {
        goToCartText: resources.buyBoxGoToCartText,
        continueShoppingText: resources.buyBoxContinueShoppingText,
        headerItemOneText: resources.buyBoxSingleItemText,
        headerItemFormatText: resources.buyBoxMultiLineItemFormatText,
        headerMessageText: resources.buyBoxHeaderMessageText,
        freePriceText: resources.priceFree,
        originalPriceText: resources.originalPriceText,
        currentPriceText: resources.currentPriceText
    };

    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            lg: { q: `w=240&h=240&m=6`, w: 0, h: 0 },
            xl: { q: `w=240&h=240&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };
    const productAvailability = state.productAvailableQuantity ?
        state.productAvailableQuantity.ProductAvailableQuantity :
        productAvailableQuantity && productAvailableQuantity.length?
            productAvailableQuantity[0].ProductAvailableQuantity: undefined;

    const isLoading = props.data.productAvailableQuantity.status === 'LOADING';
    if (isLoading) {
        callbacks.changeUpdatingDimension(false);
    }
    const telemetryContent: ITelemetryContent = getTelemetryObject(props.context.request.telemetryPageName!,
                                                                   props.friendlyName,
                                                                   props.telemetry);
    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-cart-container'
        },
        button: product && (
            <AddToCartComponent
                addToCartText="PLACE BACKORDER"//{resources.addToCartText}
                outOfStockText={resources.outOfStockText}
                navigationUrl={getUrlSync('cart', context.actionContext)}
                quantity={quantity}
                data={{product: product, price: productPrice, productDimensions: productDimensions, productAvailableQuantity: productAvailableQuantity}}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToCartFailed}
                getSelectedProduct={selectedProduct}
                productAvailability={productAvailability}
                isLoading={isLoading}
                isUpdatingDimension={isUpdatingDimension}
                changeUpdatingDimension={callbacks.changeUpdatingDimension}
                dialogStrings={dialogStrings}
                gridSettings={props.context.request.gridSettings}
                imageSettings={defaultImageSettings}
                telemetryContent= {telemetryContent}
                props = {props}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'ADDTOCART'}
            />
        )
    };
}



export function getBuyboxProductPrice(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined {
    const {
        id,
        typeName,
        context,
        data: { productPrice: { result: productPrice } },
        resources
    } = props;

    return productPrice && (
        <PriceComponent
            id={id}
            typeName={typeName}
            context={context}
            data={{price: productPrice}}
            freePriceText={resources.priceFree}
            originalPriceText={resources.originalPriceText}
            currentPriceText={resources.currentPriceText}
        />
    );
}

export function getBuyboxProductRating(props: IBuyboxProps<IBuyboxData>): React.ReactElement | undefined  {
    const {
        id,
        typeName,
        context,
        data: { ratingsSummary: { result: ratingsSummary } },
        resources
    } = props;

    const ratingComponent = ratingsSummary && ratingsSummary.averageRating && (
        <RatingComponent
            avgRating={ratingsSummary.averageRating || 0}
            readOnly={true}
            ariaLabel={format(resources.averageRatingAriaLabel, ratingsSummary.averageRating, '5')}
            ratingCount={`${ratingsSummary.reviewsCount}`}
            data={{}}
            context={context}
            id={id}
            typeName={typeName}
        />
    ) || undefined ;

    return ratingsSummary && ratingComponent && (
        ratingComponent
    );
}

export interface IBuyboxAddToWishlistViewProps {
    ContainerProps: INodeProps;
    errorBlock?: React.ReactNode;
    button?: React.ReactNode;
}
export function getBuyboxProductAddToWishlist(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxAddToWishlistViewProps | undefined {
    const {
        id,
        typeName,
        context,
        data: {
            product: { result: product },
            wishlists: { result: wishlists }
        },
        resources
    } = props;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            },
            selectedProduct
        } = state;

    const onAddToWishlistFailed = (result: IWishlistActionErrorResult) => {
        callbacks.updateErrorState({
            errorHost: 'WISHLIST',
            configureErrors: result.status === 'MISSINGDIMENSION' ? getConfigureErrors(result.missingDimensions, resources) : {},
        });
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__add-to-wishlist-container'
        },
        button: product && (
            <AddToWishlistComponent
                addToWishlistButtonText={resources.addToWishlistButtonText}
                removeFromWishlistButtonText={resources.removeFromWishlistButtonText}
                addToWishlistMessage={resources.addToWishlistMessage}
                removedFromWishlistMessage={resources.removedFromWishlistMessage}
                addItemToWishlistError={resources.addItemToWishlistError}
                removeItemFromWishlistError={resources.removeItemFromWishlistError}
                nameOfWishlist={resources.nameOfWishlist}
                data={{ product: product, wishlists: wishlists }}
                context={context}
                id={id}
                typeName={typeName}
                onError={onAddToWishlistFailed}
                getSelectedProduct={selectedProduct}
                showButtonText = {true}
                showButtonTooltip = {true}
            />
        ),
        errorBlock: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'WISHLIST'}
            />
        )
    };
}

export interface IBuyboxProductQuantityViewProps {
    ContainerProps: INodeProps;
    LabelContainerProps: INodeProps;

    heading: React.ReactNode;
    errors?: React.ReactNode;

    input: React.ReactNode;
}
export function getBuyboxProductQuantity(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxProductQuantityViewProps {
    const {
        resources,
        context: {
            app: {
                config: {
                    maxQuantityForCartLineItem
                }
            }
        }
    } = props;

    const
        {
            quantity,
            errorState: {
                quantityError,
            }
        } = state;

    const onChange = (newValue: number) => {
        if (callbacks.updateQuantity) {
            callbacks.updateQuantity(newValue);
        }
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__quantity'
        },
        LabelContainerProps: {
            tag: 'label',
            className: 'ms-buybox__product-quantity-label',
            htmlFor: 'ms-buybox__product-quantity-input'
        },
        heading: (
            <div className='ms-buybox__product-quantity-label-heading'>{resources.productQuantityHeading}</div>
        ),
        errors: quantityError && (
            <span className='msc-alert msc-alert-noborder msc-alert-danger'>
                <span className='msi-exclamation-triangle' aria-hidden='true' />
                <span>{quantityError}</span>
            </span>
        ),
        input: (
            <Quantity
                id='ms-buybox__product-quantity-input'
                max={maxQuantityForCartLineItem || 10}
                currentCount={quantity}
                onChange={onChange}
                inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
            />
        )
    };
}

export interface IBuyboxShopSimilarLookViewProps {
    ContainerProps: INodeProps;
    errors?: React.ReactNode;
    input: React.ReactNode;
}

export function getBuyboxShopSimilarLook(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks): IBuyboxShopSimilarLookViewProps {
    const {
        resources,
        context,
        data: {
            product: { result: product }
        },
    } = props;

    const
        {
            errorState: {}
        } = state;

    const navigationUrl = () => {
        const searchURL = `${getUrlSync('search', context && context.actionContext)}?productId=${product && product.RecordId}`;
        document.location.href = searchURL;
    };

    return {
        ContainerProps: {
            className: 'ms-buybox__shopsimilarlooks'
        },
        input: (
            <Button
                title={resources.shopSimilarLooksText}
                className='ms-buybox__shop-similar-looks-button'
                aria-label={resources.shopSimilarLooksText}
                onClick={navigationUrl}
            >
                {resources.shopSimilarLooksText}
            </Button>
        )
    };
}

export interface IBuyboxErrorBlockProps {
    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    otherError?: string;
    resources: IBuyboxResources;
    showError: boolean;
}
export const BuyboxErrorBlock: React.FC<IBuyboxErrorBlockProps> = ({ showError, configureErrors, quantityError, otherError, resources }) => {
    
    let errorMessages: (string | undefined)[] = [];

    errorMessages = Object.values(configureErrors).filter(message => message !== undefined);

    if (quantityError) {
        errorMessages.push(quantityError);
    }

    if (otherError) {
        errorMessages.push(otherError);
    }

    return (
        <Alert isOpen={showError && errorMessages.length > 0} color='danger' assertive={true} aria-label={resources.buyboxErrorMessageHeader} >
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                <span>{resources.buyboxErrorMessageHeader}</span>
            </div>
            {errorMessages.map((message) => {
                if (message) {
                    return (
                        <div className='msc-alert__line'>{message}</div>
                    );
                } else {
                    return null;
                }
            })}
        </Alert>
    );
};